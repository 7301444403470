import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = { class: "font-color--purple font-weight--extra margin-h--left-s" }
const _hoisted_3 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "font-weight--semi" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { class: "flex items-center mb-2" }
const _hoisted_14 = { class: "font-weight--extra" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { class: "font-weight--extra" }
const _hoisted_17 = { class: "font-weight--extra" }
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = { class: "font-weight--extra" }
const _hoisted_20 = { class: "flex items-center" }
const _hoisted_21 = { class: "font-weight--extra" }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = { class: "font-weight--extra" }
const _hoisted_24 = { class: "flex items-center" }
const _hoisted_25 = { class: "font-weight--extra" }

import { ref } from 'vue'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'
import { useTradeOperationsStore } from '@/entityes/invoice/trade_operations/trade_operations.store'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Operation, EditPen, Remove, CircleCheckFilled, Document } from '@element-plus/icons-vue'
import { ICalcExpense } from '@/entityes/invoice/trade_operations/trade_operations.type'


export default /*@__PURE__*/_defineComponent({
  __name: 'CalculatedExpenses',
  setup(__props, { expose: __expose }) {

const dialogIsOpen = ref(false)
const incomeId = ref<string | number>('')
const currentTicker = ref('')
const expensesToDelete = ref<Record<string, string | number>[]>([])
const { requestExpenses, updateExpense, destroyExpense, createExpense, deleteSelectedExpenses } =
  useTradeOperationsRepository()
const toStore = useTradeOperationsStore()
const loading = ref(false)
const currentEditingRow = ref<any>(undefined)

function openDialog(id: number | string, ticker: string) {
  dialogIsOpen.value = true
  incomeId.value = id
  currentTicker.value = ticker
  loading.value = true
  requestExpenses(id)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function update(expense: ICalcExpense) {
  loading.value = true
  updateExpense(expense)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `обновлен расход ${expense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `обновления расхода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function destroy(expense: ICalcExpense) {
  destroyExpense(expense.id)
    .then(() => {
      requestExpenses(incomeId.value)
    })
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `удален расход ${expense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления расхода ${expense.id} | ${err}`,
        type: 'error',
      })
    })
}
function addExpense() {
  loading.value = true
  createExpense(incomeId.value)
    .then(() => {
      requestExpenses(incomeId.value).then(() => {
        ElNotification({
          title: 'Успешно',
          message: `создан расход`,
          type: 'success',
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания расхода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function chooseToBulkDelete(val) {
  expensesToDelete.value.splice(0, expensesToDelete.value.length, ...val.map((i) => i.id))
}
function bulkDeleteExpenses() {
  loading.value = true
  deleteSelectedExpenses(expensesToDelete.value)
    .then(() => {
      requestExpenses(incomeId.value)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}
__expose({ openDialog })

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "close-on-press-escape": false,
    modelValue: dialogIsOpen.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dialogIsOpen).value = $event)),
    width: "70%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _cache[2] || (_cache[2] = _createTextVNode(" Расходы ")),
        _createElementVNode("span", _hoisted_2, _toDisplayString(currentTicker.value), 1)
      ]),
      (!!_unref(toStore).expenses)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives((_openBlock(), _createBlock(_component_el_table, {
              data: _unref(toStore).expenses,
              class: _normalizeClass(["mb-8", loading.value ? 'table-height-loading' : '']),
              "max-height": "800",
              "table-layout": "auto",
              stripe: "",
              border: "",
              tabindex: "-1",
              onSelectionChange: chooseToBulkDelete,
              onCellDblclick: openIncomeEditor,
              onKeyup: [
                _withKeys(closeIncomeEditor, ["esc"]),
                _cache[0] || (_cache[0] = _withKeys(($event: any) => (update(currentEditingRow.value)), ["enter"]))
              ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  type: "selection",
                  width: "40"
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "",
                  width: "200",
                  label: "description"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.xlsx_description,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.xlsx_description) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(scope.row.xlsx_description), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "date",
                  width: "180"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_date_picker, {
                          key: 0,
                          modelValue: scope.row.date,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.date) = $event),
                          type: "datetime",
                          format: "YYYY/MM/DD hh:mm:ss",
                          "value-format": "YYYY-MM-DD",
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("p", _hoisted_6, _toDisplayString(scope.row?.date?.split('T')[0]), 1),
                          _createElementVNode("p", null, _toDisplayString(scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0]), 1)
                        ]))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "expense code",
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.code,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.code) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(scope.row.code), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "qty",
                  width: "120"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.qty,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.qty) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(scope.row.qty), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "currency_sum",
                  width: "140",
                  prop: "currency_sum"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.currency_sum,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.currency_sum) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(scope.row.currency_sum), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "okv_country",
                  width: "140"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.okv_country,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.okv_country) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(scope.row.okv_country), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "rub_sum",
                  width: "140"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.showEditor)
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: scope.row.rub_sum,
                          "onUpdate:modelValue": ($event: any) => ((scope.row.rub_sum) = $event),
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(scope.row.rub_sum), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { width: "100" }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      (expensesToDelete.value.length > 0)
                        ? (_openBlock(), _createBlock(_component_el_tooltip, {
                            key: 0,
                            class: "box-item",
                            effect: "dark",
                            content: "Удалить все",
                            placement: "left"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                loading: loading.value,
                                type: "danger",
                                onClick: bulkDeleteExpenses,
                                icon: _unref(Remove),
                                size: "small"
                              }, null, 8, ["loading", "icon"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_el_tooltip, {
                            key: 1,
                            class: "box-item",
                            effect: "dark",
                            content: "Создать расход",
                            placement: "left"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: _unref(CirclePlusFilled),
                                loading: loading.value,
                                type: "primary",
                                onClick: addExpense
                              }, null, 8, ["icon", "loading"])
                            ]),
                            _: 1
                          }))
                    ])
                  ]),
                  default: _withCtx((scope) => [
                    _createVNode(_component_el_dropdown, { trigger: "click" }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_dropdown_item, {
                              class: "p-0 py-2",
                              disabled: scope.row.showEditor
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_popover, {
                                  disabled: scope.row.showEditor,
                                  placement: "left",
                                  title: "Инфо о расходе",
                                  width: 300,
                                  trigger: "hover"
                                }, {
                                  reference: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      disabled: scope.row.showEditor,
                                      text: "",
                                      class: "w-full",
                                      icon: _unref(Document),
                                      size: "small",
                                      type: "primary"
                                    }, {
                                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                                        _createTextVNode(" Доп информация ")
                                      ])),
                                      _: 2
                                    }, 1032, ["disabled", "icon"])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_13, [
                                      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "id:", -1)),
                                      _createElementVNode("p", _hoisted_14, _toDisplayString(scope.row.id), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_15, [
                                      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "rate:", -1)),
                                      _createElementVNode("p", _hoisted_16, _toDisplayString(scope.row.rate), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "source_file_name:", -1)),
                                      _createElementVNode("p", _hoisted_17, _toDisplayString(scope.row.source_file_name), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_18, [
                                      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "created_at:", -1)),
                                      _createElementVNode("p", _hoisted_19, _toDisplayString(scope.row.created_at), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_20, [
                                      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "updated_at:", -1)),
                                      _createElementVNode("p", _hoisted_21, _toDisplayString(scope.row.updated_at), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_22, [
                                      _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "calculated_income_id:", -1)),
                                      _createElementVNode("p", _hoisted_23, _toDisplayString(scope.row.calculated_income_id), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "first_calculation:", -1)),
                                      _createElementVNode("p", _hoisted_25, _toDisplayString(scope.row.first_calculation), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["disabled"])
                              ]),
                              _: 2
                            }, 1032, ["disabled"]),
                            _createVNode(_component_el_dropdown_item, {
                              class: "p-0 py-2",
                              disabled: scope.row.showEditor,
                              onClick: ($event: any) => (openIncomeEditor(scope.row))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  disabled: scope.row.showEditor,
                                  text: "",
                                  size: "small",
                                  type: "primary",
                                  icon: _unref(EditPen),
                                  class: "w-full"
                                }, {
                                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                                    _createTextVNode(" Редактировать ")
                                  ])),
                                  _: 2
                                }, 1032, ["disabled", "icon"])
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"]),
                            (scope.row.showEditor)
                              ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                  key: 0,
                                  class: "p-0 py-2",
                                  onClick: ($event: any) => (update(scope.row))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      text: "",
                                      class: "w-full",
                                      icon: _unref(CircleCheckFilled),
                                      size: "small",
                                      type: "success"
                                    }, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode(" Сохранить ")
                                      ])),
                                      _: 1
                                    }, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (scope.row.showEditor)
                              ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                  key: 1,
                                  class: "p-0 py-2",
                                  onClick: ($event: any) => (scope.row.showEditor = false)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_button, {
                                      text: "",
                                      class: "w-full",
                                      icon: _unref(Remove),
                                      size: "small",
                                      type: "danger"
                                    }, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode(" Отменить ")
                                      ])),
                                      _: 1
                                    }, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_el_dropdown_item, {
                              class: "p-0 py-2",
                              onClick: ($event: any) => (destroy(scope.row, incomeId.value))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  disabled: scope.row.showEditor,
                                  text: "",
                                  class: "w-full",
                                  type: "danger",
                                  size: "small",
                                  icon: _unref(Remove)
                                }, {
                                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                                    _createTextVNode(" Удалить ")
                                  ])),
                                  _: 2
                                }, 1032, ["disabled", "icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          type: "info",
                          icon: _unref(Operation),
                          size: "small"
                        }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "class"])), [
              [_directive_loading, loading.value]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})