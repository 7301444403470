import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center font-color--deep-gray mb-2" }
const _hoisted_2 = { class: "flex items-center font-color--deep-gray mb-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex items-center font-color--deep-gray" }
const _hoisted_5 = { class: "mr-2 font-weight--extra" }

import useSummaryNew from '@/05-Features/DeclarationSummary/model/useSummaryNew'
import { computed } from 'vue'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'

interface IProps {
  actions: Record<GoodsTypeName, string[]>
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DeclarationSummary',
  props: {
    actions: {}
  },
  setup(__props: any) {

const props = __props
// const declarationComplete = computed(() =>
//   props.actions?.Declaration?.reduce((acc: boolean, item: string) => {
//     return acc && ['Transfer_resolved', 'Missing_resolved', 'Short_resolved'].includes(item)
//   }, true)
// )
// const stepStatus = computed(() => {
//   if (props.actions.Declaration?.includes('Transfer')) {
//     return 'Переводы с других счетов'
//   }
//   if (props.actions.Declaration?.includes('Missing')) {
//     return 'Нет данных о покупке'
//   }
//   if (props.actions.Declaration?.includes('Short')) {
//     return 'Незакрытые короткие позиции'
//   }
//   if (props.actions.Declaration?.includes('Personal_data')) {
//     return 'Персональные даннные'
//   }
//   if (props.actions.Declaration?.includes('Add_income')) {
//     return 'Дополнительные доходы'
//   }
//   if (props.actions.Declaration?.includes('Deduction')) {
//     return 'Вычеты'
//   }
//   if (props.actions.Cfr?.includes('Add_account')) {
//     return 'Указать данные по счету для ОДС'
//   }
//   if (props.actions.Cfr?.includes('Need_file_for_last_year')) {
//     return 'Необходимо догрузить файлы FF'
//   }
//   return 'ошибка определения статуса'
// })
const titleList = {
  additional_incomes: 'Дополнительные доходы',
  deductions: 'Вычеты',
  personal_data: 'Персональные данные',
}

const { summary, taxes15, taxes13 } = useSummaryNew()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mr-2 font-weight--extra" }, "Налог 13%:", -1)),
      _createElementVNode("div", null, _toDisplayString(_unref(taxes13)) + " ₽", 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mr-2 font-weight--extra" }, "Налог 15%:", -1)),
      _createElementVNode("div", null, _toDisplayString(_unref(taxes15)) + " ₽", 1)
    ]),
    (Object.keys(_unref(summary)).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(summary), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index + Date.now(),
              class: "mb-2"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (value, key) => {
                return (_openBlock(), _createElementBlock("div", { key: key }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(titleList[key]) + ":", 1),
                    _createElementVNode("div", null, _toDisplayString(key === 'personal_data' ? value : value.length), 1)
                  ])
                ]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})