<script lang="ts" setup>
import { UnwrapNestedRefs, watch } from 'vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { useForm, FieldArray } from 'vee-validate'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'

import * as yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import useWindowWidth from '@/use/useWindowWidth'
import currency from '@/describers/CurrencyDescriber'
import { cloneDeep } from 'lodash'
import { setTouchedHelper } from '../lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}
const emit = defineEmits<IEmits>()

setTouchedHelper(props.item)
const { screenLessValue } = useWindowWidth()

const mainSchema = {
  rus: yup.bool().required(),
  physical_person: yup.bool().required(),
}

const validationSchema = toTypedSchema(
  yup.object({
    ...mainSchema,
    other_attributes: yup.object({
      // rus physical
      name: yup.string().test('name-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === true) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ФИОИн
      // rus organization
      name_organization: yup.string().test('name_organization-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // Наим
      inn: yup
        .string()
        .checkNumberString()
        .length(10)
        .test('inn-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      kpp: yup
        .string()
        .checkNumberString()
        .length(9)
        .test('kpp-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      oktmo: yup
        .string()
        .checkNumberString()
        .length(8)
        .test('oktmo-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      // !rus
      name_income: yup.string().test('name-income-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // НаимИстДох
      oksm: yup.number().test('oksm-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ОКСМ ИСТ
      oksm_broker: yup.number().test('oksm-broker-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ОКСМ ЗАЧ
      //
      property_type: yup.bool(),
      // builds
      rental_builds: yup
        .array()
        .ensure()
        .min(1, 'Должен быть хотя бы один период получения дохода')
        .required()
        .of(
          yup.object({
            date: yup.string().checkYearValue(props.yearToCalc).required(),
            amount: yup.string().required('Поле обязательно для заполнения'),
            currency: yup.string().test('oksm-test', 'Поле является обязательным', (value, context) => {
              const rus = context.from?.[2].value.rus
              if (rus === false) {
                return yup.string().required().isValidSync(value)
              }
              return true
            }),
            paid_tax: yup.string(),
            paid_tax_date: yup
              .string()
              // .checkYearValue(props.yearToCalc)
              .test('check_tax_date_validation', function (value) {
                const { createError, path } = this
                if (!!this.parent.paid_tax) {
                  const reverse = value?.split('.').reverse().join('/') || ''
                  const d = new Date(reverse)
                  if (!value) return createError({ path, message: 'Поле является обязательным' })
                  return (
                    d.getFullYear() === props.yearToCalc ||
                    createError({ path, message: 'Год не соответсвует отчетному периоду' })
                  )
                }
                return true
              }),
          })
        ),
    }),
  })
)

const { values, validate, errorBag } = useForm({
  validationSchema,
  initialValues: Object.assign(
    {
      rus: true,
      physical_person: true,
      other_attributes: {
        property_type: false,
        rental_builds: [
          {
            // amount: 0,
            date: '',
            currency: 'RUB',
          },
        ],
      },
    },
    {
      ...props.item.item,
    }
  ),
  validateOnMount: true,
})

async function prepareItem() {
  const result = Object.assign(
    {
      ...cloneDeep(values),
    },
    {
      valid: (await validate()).valid,
    }
  )
  // result['date'] = '01-01-2024'
  console.log('prepareItem', result)
  console.log('VALIDATE prepareItem', await validate())
  emit('update-item', result)
}
watch(values, (val) => {
  prepareItem()
})
prepareItem()
</script>

<template>
  <div class="px-4 pt-4 mb-8">
    <div class="mb-2">
      <div class="flex items-center">
        <p class="font-semibold mr-1">Доход получен</p>
        <question-popover>
          <div v-html="helpDescriber?.income_info" />
        </question-popover>
      </div>
      <FormItem name="rus" v-slot="{ value, onInput }">
        <el-radio-group
          :model-value="value"
          @change="
            (val: boolean) => {
              onInput(val)
            }
          "
        >
          <el-radio :value="true">в РФ</el-radio>
          <el-radio :value="false">в иностранном государстве</el-radio>
        </el-radio-group>
      </FormItem>
    </div>

    <div v-if="!values.rus" class="flex items-center flex-wrap">
      <div class="mr-8">
        <div class="flex mb-1 items-center font-semibold">
          <p>Страна источника выплаты</p>
          <question-popover>
            <div v-html="helpDescriber.oksm" />
          </question-popover>
        </div>
        <FormItem name="other_attributes.oksm" v-slot="{ value, onInput, onBlur }">
          <el-select
            :model-value="value"
            @update:model-value="onInput"
            filterable
            placeholder="Выберите страну"
            @blur="onBlur"
          >
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </FormItem>
      </div>
      <div>
        <div class="flex items-center mb-1 font-semibold">
          <p>Страна зачисления выплаты</p>
          <question-popover>
            <div v-html="helpDescriber.oksm_broker" />
          </question-popover>
        </div>
        <FormItem name="other_attributes.oksm_broker" v-slot="{ value, onBlur, onInput }">
          <el-select
            :model-value="value"
            @update:model-value="onInput"
            filterable
            placeholder="Выберите страну"
            @blur="onBlur"
          >
            <el-option
              v-for="item in useCountryDescriber()"
              :key="item.code"
              :value="item.code"
              :label="item.shortname"
            />
          </el-select>
        </FormItem>
      </div>
    </div>
  </div>
  <div class="px-4 mb-4">
    <div class="flex items-center mb-2">
      <p class="font-semibold mr-1">Тип недвижимости</p>
      <question-popover>
        <div v-html="helpDescriber.type" />
      </question-popover>
    </div>
    <FormItem name="other_attributes.property_type" v-slot="{ value, onInput }">
      <el-switch :model-value="value" @update:model-value="onInput" active-text="Нежилая" inactive-text="Жилая" />
    </FormItem>
  </div>
  <div class="el-divider-purple my-4"></div>
  <div class="px-4">
    <div class="mb-4">
      <div class="flex items-center mb-1 font-semibold">
        <p class="font-semibold mr-1">Другая сторона договора</p>
        <question-popover>
          <div v-html="helpDescriber.other_side" />
        </question-popover>
      </div>
      <FormItem name="physical_person" v-slot="{ value, onInput }">
        <el-radio-group :model-value="value" @change="(val: boolean) => {onInput(val)}">
          <el-radio :value="false">Юридическое лицо</el-radio>
          <el-radio :value="true">Физическое лицо</el-radio>
        </el-radio-group>
      </FormItem>
    </div>
    <div class="flex items-center flex-wrap">
      <div v-if="values.rus">
        <div v-if="values.physical_person">
          <p class="mb-1 font-semibold">ФИО</p>
          <FormItem name="other_attributes.name" v-slot="{ value, onBlur, onInput }">
            <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" />
          </FormItem>
        </div>
        <div class="flex items-center flex-wrap" v-else>
          <div class="mr-4">
            <p class="font-semibold mb-1">Наименование организации</p>
            <FormItem name="other_attributes.name_organization" v-slot="{ value, onBlur, onInput }">
              <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" />
            </FormItem>
          </div>
          <div class="mr-4">
            <p class="mb-1 font-semibold">ИНН</p>
            <FormItem name="other_attributes.inn" v-slot="{ value, onBlur, onInput }">
              <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" placeholder="1234567890" />
            </FormItem>
          </div>
          <div class="mr-4">
            <p class="mb-1 font-semibold">КПП</p>
            <FormItem name="other_attributes.kpp" v-slot="{ value, onBlur, onInput }">
              <el-input
                placeholder="123456789"
                v-mask="['#########']"
                :model-value="value"
                @update:model-value="onInput"
                @blur="onBlur"
              />
            </FormItem>
          </div>
          <div>
            <p class="mb-1 font-semibold">ОКТМО</p>
            <FormItem name="other_attributes.oktmo" v-slot="{ value, onBlur, onInput }">
              <el-input
                v-mask="['########']"
                :model-value="value"
                @update:model-value="onInput"
                @blur="onBlur"
                placeholder="12345678"
              />
            </FormItem>
          </div>
        </div>
      </div>
      <div class="flex items-center flex-wrap" v-else>
        <div>
          <p class="font-semibold mb-1">Наименование</p>
          <FormItem name="other_attributes.name_income" v-slot="{ value, onBlur, onInput }">
            <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" />
          </FormItem>
        </div>
      </div>
    </div>
  </div>
  <div class="el-divider-purple my-4"></div>
  <div class="mb-4">
    <div class="px-4">
      <div class="flex items-center mb-4">
        <p class="font-semibold mr-1">Периоды получения доходов</p>
        <question-popover>
          <div v-html="helpDescriber.periods" />
        </question-popover>
      </div>

      <FieldArray name="other_attributes.rental_builds" v-slot="{ fields, push, remove }">
        <div v-for="(field, idx) in fields" :key="field.key" class="mb-4">
          <el-row :gutter="6" :class="screenLessValue(992) ? 'item-margins' : ''" align="middle">
            <el-col :xs="24" :sm="24" :md="values.rus ? 6 : 4" :lg="values.rus ? 6 : 4">
              <div class="flex items-center mb-1 font-semibold">
                <p class="font-semibold mr-1">{{ values.rus ? 'Сумма дохода' : 'Сум. дох. в валюте' }}</p>
                <question-popover>
                  <div v-html="helpDescriber.amount" />
                </question-popover>
              </div>
              <form-item :name="`other_attributes.rental_builds[${idx}].amount`" v-slot="{ value, onInput, onBlur }">
                <el-input @update:model-value="onInput" :model-value="value" @blur="onBlur" />
              </form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="values.rus ? 6 : 5">
              <div class="flex items-center mb-1 font-semibold">
                <p class="font-semibold mr-1">Дата получения дохода</p>
                <question-popover>
                  <div v-html="helpDescriber.date" />
                </question-popover>
              </div>
              <form-item
                class="max-w[100px]"
                :name="`other_attributes.rental_builds[${idx}].date`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-date-picker
                  :model-value="value"
                  @update:model-value="onInput"
                  @blur="onBlur"
                  type="date"
                  placeholder="Выберите дату"
                  format="DD.MM.YYYY"
                  value-format="DD.MM.YYYY"
                />
              </form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="3" v-if="!values.rus">
              <div class="flex items-center mb-1 font-semibold">
                <p class="font-semibold mr-1">Валюта</p>
                <question-popover>
                  <div v-html="helpDescriber.currency" />
                </question-popover>
              </div>
              <form-item :name="`other_attributes.rental_builds[${idx}].currency`" v-slot="{ value, onInput, onBlur }">
                <el-select
                  :model-value="value"
                  @update:model-value="onInput"
                  @blur="onBlur"
                  filterable
                  class="width-100"
                  placeholder="Укажите валюту"
                >
                  <el-option v-for="item in currency" :key="item.code" :value="item.code" :label="item.code" />
                </el-select>
              </form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" v-if="!values.rus">
              <div class="flex items-center mb-1 font-semibold">
                <p class="font-semibold mr-1">Сумма налога</p>
                <question-popover>
                  <div v-html="helpDescriber.paid_tax" />
                </question-popover>
              </div>
              <form-item :name="`other_attributes.rental_builds[${idx}].paid_tax`" v-slot="{ value, onInput, onBlur }">
                <el-input :model-value="value" @update:model-value="onInput" @blur="onBlur" />
              </form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="5" v-if="!values.rus">
              <div class="flex items-center mb-1 font-semibold">
                <p class="font-semibold mr-1">Дата уплаты налога</p>
                <question-popover color="#021c1a4d">
                  <div v-html="helpDescriber.paid_tax_date" />
                </question-popover>
              </div>
              <form-item
                :name="`other_attributes.rental_builds[${idx}].paid_tax_date`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-date-picker
                  :model-value="value"
                  @update:model-value="onInput"
                  @blur="onBlur"
                  type="date"
                  placeholder="Выберите дату"
                  format="DD.MM.YYYY"
                  value-format="DD.MM.YYYY"
                />
              </form-item>
            </el-col>
            <el-col :sm="24" :md="values.rus ? 12 : 24" :lg="3">
              <el-button-group class="mt-8">
                <el-tooltip effect="dark" content="Добавить новый период" placement="top">
                  <el-button type="success" @click="() => push({ date: '', amount: '' })">
                    <el-icon>
                      <CirclePlusFilled />
                    </el-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="Удалить этот период" placement="top">
                  <el-button :disabled="fields.length === 1" type="danger" @click="() => remove(idx)">
                    <el-icon>
                      <RemoveFilled />
                    </el-icon>
                  </el-button>
                </el-tooltip>
              </el-button-group>
            </el-col>
          </el-row>
        </div>
      </FieldArray>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
