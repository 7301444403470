import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 mb-8" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "flex items-center flex-wrap"
}
const _hoisted_6 = { class: "mr-8" }
const _hoisted_7 = { class: "flex mb-1 items-center font-semibold" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "px-4 mb-4" }
const _hoisted_12 = { class: "flex items-center mb-2" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "px-4" }
const _hoisted_15 = { class: "mb-4" }
const _hoisted_16 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "flex items-center flex-wrap" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 1,
  class: "flex items-center flex-wrap"
}
const _hoisted_22 = { class: "mr-4" }
const _hoisted_23 = { class: "mr-4" }
const _hoisted_24 = { class: "mr-4" }
const _hoisted_25 = {
  key: 1,
  class: "flex items-center flex-wrap"
}
const _hoisted_26 = { class: "mb-4" }
const _hoisted_27 = { class: "px-4" }
const _hoisted_28 = { class: "flex items-center mb-4" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_31 = { class: "font-semibold mr-1" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = { class: "flex items-center mb-1 font-semibold" }
const _hoisted_40 = ["innerHTML"]

import { UnwrapNestedRefs, watch } from 'vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { useForm, FieldArray } from 'vee-validate'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'

import * as yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import useWindowWidth from '@/use/useWindowWidth'
import currency from '@/describers/CurrencyDescriber'
import { cloneDeep } from 'lodash'
import { setTouchedHelper } from '../lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber: any
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Rental',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

setTouchedHelper(props.item)
const { screenLessValue } = useWindowWidth()

const mainSchema = {
  rus: yup.bool().required(),
  physical_person: yup.bool().required(),
}

const validationSchema = toTypedSchema(
  yup.object({
    ...mainSchema,
    other_attributes: yup.object({
      // rus physical
      name: yup.string().test('name-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === true) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ФИОИн
      // rus organization
      name_organization: yup.string().test('name_organization-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // Наим
      inn: yup
        .string()
        .checkNumberString()
        .length(10)
        .test('inn-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      kpp: yup
        .string()
        .checkNumberString()
        .length(9)
        .test('kpp-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      oktmo: yup
        .string()
        .checkNumberString()
        .length(8)
        .test('oktmo-test', 'Поле является обязательным', (value, context) => {
          const rus = context.from?.[1].value.rus
          const physical_person = context.from?.[1].value.physical_person
          if (rus === true && physical_person === false) {
            return yup.string().required().isValidSync(value)
          }
          return true
        }),
      // !rus
      name_income: yup.string().test('name-income-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // НаимИстДох
      oksm: yup.number().test('oksm-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ОКСМ ИСТ
      oksm_broker: yup.number().test('oksm-broker-test', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) {
          return yup.string().required().isValidSync(value)
        }
        return true
      }), // ОКСМ ЗАЧ
      //
      property_type: yup.bool(),
      // builds
      rental_builds: yup
        .array()
        .ensure()
        .min(1, 'Должен быть хотя бы один период получения дохода')
        .required()
        .of(
          yup.object({
            date: yup.string().checkYearValue(props.yearToCalc).required(),
            amount: yup.string().required('Поле обязательно для заполнения'),
            currency: yup.string().test('oksm-test', 'Поле является обязательным', (value, context) => {
              const rus = context.from?.[2].value.rus
              if (rus === false) {
                return yup.string().required().isValidSync(value)
              }
              return true
            }),
            paid_tax: yup.string(),
            paid_tax_date: yup
              .string()
              // .checkYearValue(props.yearToCalc)
              .test('check_tax_date_validation', function (value) {
                const { createError, path } = this
                if (!!this.parent.paid_tax) {
                  const reverse = value?.split('.').reverse().join('/') || ''
                  const d = new Date(reverse)
                  if (!value) return createError({ path, message: 'Поле является обязательным' })
                  return (
                    d.getFullYear() === props.yearToCalc ||
                    createError({ path, message: 'Год не соответсвует отчетному периоду' })
                  )
                }
                return true
              }),
          })
        ),
    }),
  })
)

const { values, validate, errorBag } = useForm({
  validationSchema,
  initialValues: Object.assign(
    {
      rus: true,
      physical_person: true,
      other_attributes: {
        property_type: false,
        rental_builds: [
          {
            // amount: 0,
            date: '',
            currency: 'RUB',
          },
        ],
      },
    },
    {
      ...props.item.item,
    }
  ),
  validateOnMount: true,
})

async function prepareItem() {
  const result = Object.assign(
    {
      ...cloneDeep(values),
    },
    {
      valid: (await validate()).valid,
    }
  )
  // result['date'] = '01-01-2024'
  console.log('prepareItem', result)
  console.log('VALIDATE prepareItem', await validate())
  emit('update-item', result)
}
watch(values, (val) => {
  prepareItem()
})
prepareItem()

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Доход получен", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber?.income_info
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "rus" }, {
          default: _withCtx(({ value, onInput }) => [
            _createVNode(_component_el_radio_group, {
              "model-value": value,
              onChange: 
            (val) => {
              onInput(val)
            }
          
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, { value: true }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("в РФ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_radio, { value: false }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("в иностранном государстве")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["model-value", "onChange"])
          ]),
          _: 1
        })
      ]),
      (!_unref(values).rus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("p", null, "Страна источника выплаты", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber.oksm
                    }, null, 8, _hoisted_8)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.oksm" }, {
                default: _withCtx(({ value, onInput, onBlur }) => [
                  _createVNode(_component_el_select, {
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: "",
                    placeholder: "Выберите страну",
                    onBlur: onBlur
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.shortname
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _cache[4] || (_cache[4] = _createElementVNode("p", null, "Страна зачисления выплаты", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber.oksm_broker
                    }, null, 8, _hoisted_10)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.oksm_broker" }, {
                default: _withCtx(({ value, onBlur, onInput }) => [
                  _createVNode(_component_el_select, {
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: "",
                    placeholder: "Выберите страну",
                    onBlur: onBlur
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.shortname
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Тип недвижимости", -1)),
        _createVNode(QuestionPopover, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.helpDescriber.type
            }, null, 8, _hoisted_13)
          ]),
          _: 1
        })
      ]),
      _createVNode(FormItem, { name: "other_attributes.property_type" }, {
        default: _withCtx(({ value, onInput }) => [
          _createVNode(_component_el_switch, {
            "model-value": value,
            "onUpdate:modelValue": onInput,
            "active-text": "Нежилая",
            "inactive-text": "Жилая"
          }, null, 8, ["model-value", "onUpdate:modelValue"])
        ]),
        _: 1
      })
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Другая сторона договора", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber.other_side
              }, null, 8, _hoisted_17)
            ]),
            _: 1
          })
        ]),
        _createVNode(FormItem, { name: "physical_person" }, {
          default: _withCtx(({ value, onInput }) => [
            _createVNode(_component_el_radio_group, {
              "model-value": value,
              onChange: (val) => {onInput(val)}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, { value: false }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Юридическое лицо")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_radio, { value: true }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Физическое лицо")
                  ])),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["model-value", "onChange"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_18, [
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              (_unref(values).physical_person)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "ФИО", -1)),
                    _createVNode(FormItem, { name: "other_attributes.name" }, {
                      default: _withCtx(({ value, onBlur, onInput }) => [
                        _createVNode(_component_el_input, {
                          "model-value": value,
                          "onUpdate:modelValue": onInput,
                          onBlur: onBlur
                        }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование организации", -1)),
                      _createVNode(FormItem, { name: "other_attributes.name_organization" }, {
                        default: _withCtx(({ value, onBlur, onInput }) => [
                          _createVNode(_component_el_input, {
                            "model-value": value,
                            "onUpdate:modelValue": onInput,
                            onBlur: onBlur
                          }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[11] || (_cache[11] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "ИНН", -1)),
                      _createVNode(FormItem, { name: "other_attributes.inn" }, {
                        default: _withCtx(({ value, onBlur, onInput }) => [
                          _createVNode(_component_el_input, {
                            "model-value": value,
                            "onUpdate:modelValue": onInput,
                            onBlur: onBlur,
                            placeholder: "1234567890"
                          }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _cache[12] || (_cache[12] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "КПП", -1)),
                      _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                        default: _withCtx(({ value, onBlur, onInput }) => [
                          _withDirectives(_createVNode(_component_el_input, {
                            placeholder: "123456789",
                            "model-value": value,
                            "onUpdate:modelValue": onInput,
                            onBlur: onBlur
                          }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"]), [
                            [_directive_mask, ['#########']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", null, [
                      _cache[13] || (_cache[13] = _createElementVNode("p", { class: "mb-1 font-semibold" }, "ОКТМО", -1)),
                      _createVNode(FormItem, { name: "other_attributes.oktmo" }, {
                        default: _withCtx(({ value, onBlur, onInput }) => [
                          _withDirectives(_createVNode(_component_el_input, {
                            "model-value": value,
                            "onUpdate:modelValue": onInput,
                            onBlur: onBlur,
                            placeholder: "12345678"
                          }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"]), [
                            [_directive_mask, ['########']]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", null, [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование", -1)),
                _createVNode(FormItem, { name: "other_attributes.name_income" }, {
                  default: _withCtx(({ value, onBlur, onInput }) => [
                    _createVNode(_component_el_input, {
                      "model-value": value,
                      "onUpdate:modelValue": onInput,
                      onBlur: onBlur
                    }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                  ]),
                  _: 1
                })
              ])
            ]))
      ])
    ]),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _cache[15] || (_cache[15] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Периоды получения доходов", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber.periods
              }, null, 8, _hoisted_29)
            ]),
            _: 1
          })
        ]),
        _createVNode(_unref(FieldArray), { name: "other_attributes.rental_builds" }, {
          default: _withCtx(({ fields, push, remove }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (field, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: field.key,
                class: "mb-4"
              }, [
                _createVNode(_component_el_row, {
                  gutter: 6,
                  class: _normalizeClass(_unref(screenLessValue)(992) ? 'item-margins' : ''),
                  align: "middle"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      xs: 24,
                      sm: 24,
                      md: _unref(values).rus ? 6 : 4,
                      lg: _unref(values).rus ? 6 : 4
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("p", _hoisted_31, _toDisplayString(_unref(values).rus ? 'Сумма дохода' : 'Сум. дох. в валюте'), 1),
                          _createVNode(QuestionPopover, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                innerHTML: _ctx.helpDescriber.amount
                              }, null, 8, _hoisted_32)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(FormItem, {
                          name: `other_attributes.rental_builds[${idx}].amount`
                        }, {
                          default: _withCtx(({ value, onInput, onBlur }) => [
                            _createVNode(_component_el_input, {
                              "onUpdate:modelValue": onInput,
                              "model-value": value,
                              onBlur: onBlur
                            }, null, 8, ["onUpdate:modelValue", "model-value", "onBlur"])
                          ]),
                          _: 2
                        }, 1032, ["name"])
                      ]),
                      _: 2
                    }, 1032, ["md", "lg"]),
                    _createVNode(_component_el_col, {
                      xs: 24,
                      sm: 24,
                      md: 6,
                      lg: _unref(values).rus ? 6 : 5
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, [
                          _cache[16] || (_cache[16] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата получения дохода", -1)),
                          _createVNode(QuestionPopover, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                innerHTML: _ctx.helpDescriber.date
                              }, null, 8, _hoisted_34)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(FormItem, {
                          class: "max-w[100px]",
                          name: `other_attributes.rental_builds[${idx}].date`
                        }, {
                          default: _withCtx(({ value, onInput, onBlur }) => [
                            _createVNode(_component_el_date_picker, {
                              "model-value": value,
                              "onUpdate:modelValue": onInput,
                              onBlur: onBlur,
                              type: "date",
                              placeholder: "Выберите дату",
                              format: "DD.MM.YYYY",
                              "value-format": "DD.MM.YYYY"
                            }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                          ]),
                          _: 2
                        }, 1032, ["name"])
                      ]),
                      _: 2
                    }, 1032, ["lg"]),
                    (!_unref(values).rus)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 0,
                          xs: 24,
                          sm: 24,
                          md: 4,
                          lg: 3
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_35, [
                              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Валюта", -1)),
                              _createVNode(QuestionPopover, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: _ctx.helpDescriber.currency
                                  }, null, 8, _hoisted_36)
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(FormItem, {
                              name: `other_attributes.rental_builds[${idx}].currency`
                            }, {
                              default: _withCtx(({ value, onInput, onBlur }) => [
                                _createVNode(_component_el_select, {
                                  "model-value": value,
                                  "onUpdate:modelValue": onInput,
                                  onBlur: onBlur,
                                  filterable: "",
                                  class: "width-100",
                                  placeholder: "Укажите валюту"
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item.code,
                                        value: item.code,
                                        label: item.code
                                      }, null, 8, ["value", "label"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (!_unref(values).rus)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 1,
                          xs: 24,
                          sm: 24,
                          md: 4,
                          lg: 4
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_37, [
                              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Сумма налога", -1)),
                              _createVNode(QuestionPopover, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: _ctx.helpDescriber.paid_tax
                                  }, null, 8, _hoisted_38)
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(FormItem, {
                              name: `other_attributes.rental_builds[${idx}].paid_tax`
                            }, {
                              default: _withCtx(({ value, onInput, onBlur }) => [
                                _createVNode(_component_el_input, {
                                  "model-value": value,
                                  "onUpdate:modelValue": onInput,
                                  onBlur: onBlur
                                }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (!_unref(values).rus)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 2,
                          xs: 24,
                          sm: 24,
                          md: 6,
                          lg: 5
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_39, [
                              _cache[19] || (_cache[19] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата уплаты налога", -1)),
                              _createVNode(QuestionPopover, { color: "#021c1a4d" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: _ctx.helpDescriber.paid_tax_date
                                  }, null, 8, _hoisted_40)
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(FormItem, {
                              name: `other_attributes.rental_builds[${idx}].paid_tax_date`
                            }, {
                              default: _withCtx(({ value, onInput, onBlur }) => [
                                _createVNode(_component_el_date_picker, {
                                  "model-value": value,
                                  "onUpdate:modelValue": onInput,
                                  onBlur: onBlur,
                                  type: "date",
                                  placeholder: "Выберите дату",
                                  format: "DD.MM.YYYY",
                                  "value-format": "DD.MM.YYYY"
                                }, null, 8, ["model-value", "onUpdate:modelValue", "onBlur"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_col, {
                      sm: 24,
                      md: _unref(values).rus ? 12 : 24,
                      lg: 3
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button_group, { class: "mt-8" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_tooltip, {
                              effect: "dark",
                              content: "Добавить новый период",
                              placement: "top"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  type: "success",
                                  onClick: () => push({ date: '', amount: '' })
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(CirclePlusFilled))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_tooltip, {
                              effect: "dark",
                              content: "Удалить этот период",
                              placement: "top"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_button, {
                                  disabled: fields.length === 1,
                                  type: "danger",
                                  onClick: () => remove(idx)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_unref(RemoveFilled))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["md"])
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})