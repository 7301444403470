<script lang="ts" setup>
import { ref } from 'vue'
import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import { ICommonExpense } from '@/entityes/invoice/trade_operations/trade_operations.type'
import useCommonExpensesRepository from '@/entityes/invoice/trade_operations/сommon_expenses.repository'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, Operation, EditPen, Remove, CircleCheckFilled, InfoFilled } from '@element-plus/icons-vue'

interface IProps {
  declarationId: string | number
  commonExpenses: ICommonExpense[]
  tPages: number
  cPage: number
}

const props = defineProps<IProps>()
const commonExpensesToDelete = ref([])
const search = ref('')
const sort = ref('')
const loading = ref(false)
const currentEditingRow = ref<any>(undefined)
const {
  getCommonExpenses,
  createCommonExpense,
  destroyCommonExpense,
  updateCommonExpense,
  deleteSelectedCommonExpenses,
} = useCommonExpensesRepository()

function changePage(page: number) {
  loading.value = true
  getCommonExpenses(props.declarationId, page, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function create() {
  loading.value = true
  createCommonExpense(props.declarationId)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `создан общий доход`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания общего дохода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function update(commonExpense: ICommonExpense) {
  loading.value = false
  updateCommonExpense(commonExpense)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `обновлен общий доход ${commonExpense.id}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общего дохода ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function destroy(commonExpenseId: string | number) {
  loading.value = true
  destroyCommonExpense(commonExpenseId)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `удален общий доход ${commonExpenseId}`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общих доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
const chooseToBulkDelete = (val: ICommonExpense[]) => {
  commonExpensesToDelete.splice(0, commonExpensesToDelete.length, ...val.map((i: ICommonExpense) => i.id))
}
function deleteSelectedRows() {
  loading.value = true
  deleteSelectedCommonExpenses(props.declarationId, commonExpensesToDelete)
    .then(() => {
      getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
      ElNotification({
        title: 'Успешно',
        message: `удалены общие доходы`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления общих доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter() {
  getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function sortEvent(val: string) {
  loading.value = true
  sort.value = val
  getCommonExpenses(props.declarationId, props.cPage, search.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса общих расходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}
</script>

<template>
  <div class="flex items-center justify-between mb-4">
    <el-pagination
      :current-page="props.cPage"
      :total="props.tPages"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      background
      size="small"
    />
    <el-input v-model="search" placeholder="Поиск по общим расходам" style="width: 300px" @input="tickerFilter" />
  </div>
  <el-table
    :data="props.commonExpenses"
    class="mb-4 w-full"
    table-layout="auto"
    stripe
    border
    @selection-change="chooseToBulkDelete"
    tabindex="-1"
    v-loading="loading"
    @cellDblclick="openIncomeEditor"
    @keyup.enter="update(currentEditingRow)"
    @keyup.esc="closeIncomeEditor"
  >
    <el-table-column type="selection" width="40" />
    <el-table-column label="broker" fixed width="260">
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.broker" size="small" />
        <p v-else>{{ scope.row.broker }}</p>
      </template>
    </el-table-column>
    <el-table-column label="description" width="370">
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.description" size="small" />
        <p v-else>{{ scope.row.description }}</p>
      </template>
    </el-table-column>
    <el-table-column label="date" width="140">
      <template #header>
        <div class="flex items-center justify-between">
          <p>date</p>
          <calculated-income-table-filter search-label="date" @search-event="sortEvent" element="sort" />
        </div>
      </template>
      <template #default="scope">
        <el-date-picker
          v-if="scope.row.showEditor"
          v-model="scope.row.date"
          type="datetime"
          format="YYYY/MM/DD hh:mm:ss"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <p v-else>{{ scope.row.date }}</p>
      </template>
    </el-table-column>
    <el-table-column prop="paid_sum" width="140">
      <template #header>
        <div class="flex items-center justify-between">
          <p>paid_sum</p>
          <calculated-income-table-filter search-label="paid_sum" @search-event="sortEvent" element="sort" />
        </div>
      </template>
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.paid_sum" size="small" />
        <p v-else>{{ scope.row.paid_sum }}</p>
      </template>
    </el-table-column>
    <el-table-column label="currency" width="140">
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.currency" size="small" />
        <p v-else>{{ scope.row.currency }}</p>
      </template>
    </el-table-column>
    <el-table-column label="rub_sum" width="140">
      <template #header>
        <div class="flex items-center justify-between">
          <p>rub_sum</p>
          <calculated-income-table-filter search-label="rub_sum" @search-event="sortEvent" element="sort" />
        </div>
      </template>
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.rub_sum" size="small" />
        <p v-else>{{ scope.row.rub_sum }}</p>
      </template>
    </el-table-column>
    <el-table-column label="rate" width="140">
      <template #header>
        <div class="flex items-center justify-between">
          <p>rate</p>
          <calculated-income-table-filter search-label="rub_sum" @search-event="sortEvent" element="sort" />
        </div>
      </template>
      <template #default="scope">
        <el-input v-if="scope.row.showEditor" v-model="scope.row.rate" size="small" />
        <p v-else>{{ scope.row.rate }}</p>
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="40">
      <template #header>
        <div class="flex items-center justify-between">
          <el-button
            @click="deleteSelectedRows"
            type="danger"
            v-if="commonExpensesToDelete.length > 0"
            :icon="Remove"
          />
          <el-button :icon="CirclePlusFilled" v-else type="primary" @click="create" />
        </div>
      </template>
      <template #default="scope">
        <el-dropdown trigger="click">
          <el-button type="info" :icon="Operation" size="small" />
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item class="p-0 py-2">
                <el-popover
                  :disabled="scope.row.showEditor"
                  placement="left"
                  title="Инфо об общем доходе"
                  :width="240"
                  trigger="hover"
                >
                  <template #reference>
                    <el-button
                      :icon="InfoFilled"
                      :disabled="scope.row.showEditor"
                      text
                      size="small"
                      type="primary"
                      class="w-full"
                    >
                      Доп информация
                    </el-button>
                  </template>
                  <template #default>
                    <div class="flex items-center mb-2">
                      <p class="mr-2 font-bold font-color--purple">id:</p>
                      <p class="font-weight--extra">{{ scope.row.id }}</p>
                    </div>
                    <div class="flex items-center mb-2">
                      <p class="mr-2 font-bold font-color--purple">static_expense_id:</p>
                      <p class="font-weight--extra margin-h--left-s">
                        {{ scope.row.static_expense_id }}
                      </p>
                    </div>
                  </template>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item :disabled="scope.row.showEditor" @click="openIncomeEditor(scope.row)" class="p-0 py-2">
                <el-button
                  :disabled="scope.row.showEditor"
                  class="w-full"
                  :icon="EditPen"
                  size="small"
                  text
                  type="primary"
                >
                  Редактировать
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item @click="update(scope.row)" v-if="scope.row.showEditor" class="p-0 py-2">
                <el-button class="w-full" text :icon="CircleCheckFilled" size="small" type="success">
                  Сохранить
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item @click="closeIncomeEditor" v-if="scope.row.showEditor" class="p-0 py-2">
                <el-button class="w-full" type="danger" text :icon="Remove" size="small"> Отменить </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped lang="sass"></style>
