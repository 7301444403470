import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "px-4 pt-4 mb-4" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "flex items-center mb-1" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "flex items-center mb-1" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "flex flex-wrap mb-4"
}
const _hoisted_9 = { class: "mr-4" }
const _hoisted_10 = { class: "flex items-center mb-1" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex items-center mb-1" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "flex items-center mb-4" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "px-4" }
const _hoisted_19 = { class: "flex items-center flex-wrap mb-2" }
const _hoisted_20 = { class: "mr-4" }
const _hoisted_21 = { class: "flex items-center mb-1" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_24 = { class: "flex items-center mb-1" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = { class: "flex items-center mb-1" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_29 = { class: "flex items-center flex-wrap" }
const _hoisted_30 = { class: "mr-4" }
const _hoisted_31 = { class: "flex items-center mb-1" }
const _hoisted_32 = ["innerHTML"]
const _hoisted_33 = { class: "flex items-center mb-1" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = {
  key: 1,
  class: "mb-4"
}
const _hoisted_36 = { class: "flex items-center flex-wrap mb-4" }
const _hoisted_37 = { class: "mr-4" }
const _hoisted_38 = { class: "flex items-center mb-1" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_41 = { class: "flex items-center mb-1" }
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = { class: "flex items-center mb-1" }
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = { class: "px-4 mb-4" }
const _hoisted_46 = { class: "mb-4" }
const _hoisted_47 = { class: "flex items-center mb-1" }
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { class: "flex items-center flex-wrap" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_53 = {
  key: 1,
  class: "flex items-center flex-wrap"
}
const _hoisted_54 = { class: "mr-4" }
const _hoisted_55 = { class: "mr-4" }
const _hoisted_56 = { class: "mr-4" }
const _hoisted_57 = { class: "mr-4" }
const _hoisted_58 = { key: 1 }
const _hoisted_59 = { class: "flex items-center flex-wrap" }

import { ref, toRef, UnwrapNestedRefs, watch, watchEffect } from 'vue'
import currency from '@/describers/CurrencyDescriber'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { AdditionalIncome, AdditionalItem, AdditionalItemValue } from '@/06-Entities/IncomeAndRefund'
import { toTypedSchema } from '@vee-validate/yup'
import { string as ystr, object as yobj, bool } from 'yup'
import { getFields } from '../model/formFields'
import { useForm } from 'vee-validate'
import { cloneDeep } from 'lodash'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ElMessageBox } from 'element-plus'
import { setTouchedHelper } from '../lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<AdditionalIncome>>
  yearToCalc: number
  helpDescriber?: { [key: string]: string }
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TransportSale',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

setTouchedHelper(props.item)
const { mainFields } = getFields(props.yearToCalc)
const schema = yobj({
  ...mainFields,
  other_attributes: yobj({
    // rus physyc
    name: ystr().test('name-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === true) return ystr().required().isValidSync(value)
      return true
    }), // ФИОИн
    // rus organization
    name_organization: ystr().test('name_organization-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      const physical_person = context.from?.[1].value.physical_person
      if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
      return true
    }), // Наим
    inn: ystr()
      .checkNumberString()
      .length(10)
      .test('inn-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),
    kpp: ystr()
      .checkNumberString()
      .length(9)
      .test('kpp-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),
    oktmo: ystr()
      .checkNumberString()
      .length(8)
      .test('oktmo-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        const physical_person = context.from?.[1].value.physical_person
        if (rus === true && physical_person === false) return ystr().required().isValidSync(value)
        return true
      }),

    // all outh country
    name_income: ystr().test('name_income-required', 'Поле является обязательным', (value, context) => {
      const rus = context.from?.[1].value.rus
      if (rus === false) return ystr().required().isValidSync(value)
      return true
    }), // НаимИстДох
    oksm: ystr()
      .checkNumberString()
      .test('oksm-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return ystr().required().isValidSync(value)
        return true
      }), // ОКСМ ИСТ
    oksm_broker: ystr()
      .checkNumberString()
      .test('oksm_broker-required', 'Поле является обязательным', (value, context) => {
        const rus = context.from?.[1].value.rus
        if (rus === false) return ystr().required().isValidSync(value)
        return true
      }), // ОКСМ ЗАЧ
    // paid tax
    paid_tax: ystr().checkNumberString(),
    paid_tax_date: ystr().test('check_tax_date_validation', function (value) {
      const { createError, path } = this
      if (!!this.parent.paid_tax) {
        const reverse = value?.split('.').reverse().join('/') || ''
        const d = new Date(reverse)
        if (!value) return createError({ path, message: 'Поле является обязательным' })
        return (
          d.getFullYear() === props.yearToCalc ||
          createError({ path, message: 'Год не соответсвует отчетному периоду' })
        )
      }
      return true
    }),
    // expense
    purchasedByYourself: bool().default(![undefined, ''].includes(props.item.item?.other_attributes?.expense_date)),
    expense_currency: ystr()
      .length(3)
      .when('purchasedByYourself', {
        is: true,
        then: (schema) => schema.required(),
      }),
    expense_date: ystr().when('purchasedByYourself', {
      is: true,
      then: (schema) => schema.required(),
    }),
    amount_of_expenses: ystr().checkNumberString(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, setValues, errorBag } = useForm({
  validationSchema,
  initialValues: { ...props.item.item },
  validateOnMount: true,
})

const estimate = ref(false)
// если владеет машиной более 3-х лет
watchEffect(() => {
  if (estimate.value) {
    ElMessageBox.confirm(
      'Если срок владения транспортом более 3-х лет, то декларировать доход от его продажи нет необходимости.',
      {
        confirmButtonText: 'Удалить этот доход',
        cancelButtonText: 'Не удалять',
        type: 'warning',
      }
    )
      .then(() => {
        emit('delete')
      })
      .catch(() => {
        estimate.value = false
      })
  }
})

function setOksm(rus: boolean) {
  if (rus) {
    setValues({
      currency: 'RUB',
      other_attributes: {
        expense_currency: 'RUB',
        oksm: '643',
        oksm_broker: '643',
      },
    })
  }
}
watch(
  toRef(values.rus),
  (rus) => {
    if (rus === true) setOksm(rus)
  },
  { immediate: true }
)

async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  // console.log('Transport', result)
  // console.log('Transport VALIDATE', await validate())
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Срок владения", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.estimate
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_el_radio, {
                  modelValue: estimate.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((estimate).value = $event)),
                  label: false
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("До 3-х лет")
                  ])),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_radio, {
                  modelValue: estimate.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((estimate).value = $event)),
                  label: true
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Более 3-х лет")
                  ])),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Доход получен", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.income_info
                }, null, 8, _hoisted_7)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "rus" }, {
            default: _withCtx(({ value, onInput }) => [
              _createVNode(_component_el_radio_group, {
                "model-value": value,
                onChange: 
            (val) => {
              onInput(val)
            }
          
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio, { value: true }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("в РФ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_radio, { value: false }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("в иностранном государстве")
                    ])),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["model-value", "onChange"])
            ]),
            _: 1
          })
        ]),
        (!_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Страна источника выплаты", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.oksm
                      }, null, 8, _hoisted_11)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.oksm" }, {
                  default: _withCtx(({ field, value, onInput, onBlur }) => [
                    _createVNode(_component_el_select, {
                      filterable: "",
                      placeholder: "Выберите страну",
                      "model-value": value,
                      "onUpdate:modelValue": onInput,
                      onBlur: onBlur
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.code,
                            value: item.code,
                            label: item.shortname
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Страна зачисления выплаты", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.oksm_broker
                      }, null, 8, _hoisted_13)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.oksm_broker" }, {
                  default: _withCtx(({ onInput, value, onBlur }) => [
                    _createVNode(_component_el_select, {
                      filterable: "",
                      "onUpdate:modelValue": onInput,
                      onBlur: onBlur,
                      placeholder: "Выберите страну",
                      "model-value": value
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(useCountryDescriber)(), (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.code,
                            value: item.code,
                            label: item.shortname
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["onUpdate:modelValue", "onBlur", "model-value"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_14, [
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Наличие документов о покупке транспорта", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber?.purchase_by_yourself
              }, null, 8, _hoisted_15)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(FormItem, { name: "other_attributes.purchasedByYourself" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_radio_group, _mergeProps(field, { "model-value": value }), {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio, { value: true }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("Да")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_el_radio, { value: false }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Нет")
                    ])),
                    _: 1
                  }),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.purchase_by_yourself_no
                      }, null, 8, _hoisted_17)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1040, ["model-value"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _cache[31] || (_cache[31] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_18, [
      _cache[21] || (_cache[21] = _createElementVNode("p", { class: "font-semibold mb-4" }, "Данные о доходе", -1)),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Цена продажи", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.amount
                }, null, 8, _hoisted_22)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "amount" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ]),
        (!_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "font-weight--extra mr-1" }, "Валюта продажи", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.currency
                    }, null, 8, _hoisted_25)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "currency" }, {
                default: _withCtx(({ field, value, onInput }) => [
                  _createVNode(_component_el_select, {
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: "",
                    placeholder: "Укажите валюту"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          value: item.code,
                          label: item.code
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_26, [
            _cache[15] || (_cache[15] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата получения дохода", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.date
                }, null, 8, _hoisted_27)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "date" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_date_picker, _mergeProps(field, {
                "model-value": value,
                type: "date",
                placeholder: "Выберите дату",
                format: "DD.MM.YYYY",
                "value-format": "DD.MM.YYYY"
              }), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ])
      ]),
      (!_unref(values).rus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Налог в иностранном государстве", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.paid_tax_transport
                      }, null, 8, _hoisted_32)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.paid_tax" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_33, [
                  _cache[17] || (_cache[17] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата уплаты налога", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.paid_tax_transport_date
                      }, null, 8, _hoisted_34)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.paid_tax_date" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_date_picker, _mergeProps({
                      type: "date",
                      placeholder: "Выберите дату",
                      format: "DD.MM.YYYY",
                      "value-format": "DD.MM.YYYY"
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(values).other_attributes?.purchasedByYourself)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Цена покупки", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.transport_price
                      }, null, 8, _hoisted_39)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.amount_of_expenses" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              (!_unref(values).rus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _cache[19] || (_cache[19] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Валюта покупки", -1)),
                      _createVNode(QuestionPopover, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: _ctx.helpDescriber?.expense_currency
                          }, null, 8, _hoisted_42)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(FormItem, { name: "other_attributes.expense_currency" }, {
                      default: _withCtx(({ field, value, onInput }) => [
                        _createVNode(_component_el_select, {
                          "model-value": value,
                          "onUpdate:modelValue": onInput,
                          filterable: "",
                          placeholder: "Укажите валюту"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currency), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.code,
                                value: item.code,
                                label: item.code
                              }, null, 8, ["value", "label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["model-value", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_43, [
                  _cache[20] || (_cache[20] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата покупки", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.expense_date
                      }, null, 8, _hoisted_44)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.expense_date" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_date_picker, _mergeProps({
                      type: "date",
                      placeholder: "Выберите дату",
                      format: "DD.MM.YYYY",
                      "value-format": "DD.MM.YYYY"
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[32] || (_cache[32] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1)),
    _createElementVNode("div", _hoisted_45, [
      _createElementVNode("div", _hoisted_46, [
        _createElementVNode("div", _hoisted_47, [
          _cache[22] || (_cache[22] = _createElementVNode("p", { class: "font-semibold" }, "Другая сторона договора", -1)),
          _createVNode(QuestionPopover, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.helpDescriber?.name_income
              }, null, 8, _hoisted_48)
            ]),
            _: 1
          })
        ]),
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              _createVNode(FormItem, { name: "physical_person" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_radio_group, _mergeProps(field, { "model-value": value }), {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio, { value: false }, {
                        default: _withCtx(() => _cache[23] || (_cache[23] = [
                          _createTextVNode("Юридическое лицо")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_el_radio, { value: true }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode("Физическое лицо")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_50, [
        (_unref(values).rus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
              (_unref(values).physical_person)
                ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                    _cache[25] || (_cache[25] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ФИО", -1)),
                    _createVNode(FormItem, { name: "other_attributes.name" }, {
                      default: _withCtx(({ field, value }) => [
                        _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    _createElementVNode("div", _hoisted_54, [
                      _cache[26] || (_cache[26] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование организации", -1)),
                      _createVNode(FormItem, { name: "other_attributes.name_organization" }, {
                        default: _withCtx(({ field, value }) => [
                          _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _cache[27] || (_cache[27] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ИНН", -1)),
                      _createVNode(FormItem, { name: "other_attributes.inn" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps({ "model-value": value }, field, { placeholder: "1234567890" }), null, 16, ["model-value"]), [
                            [_directive_mask, ['############']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_56, [
                      _cache[28] || (_cache[28] = _createElementVNode("p", { class: "font-semibold mb-1" }, "КПП", -1)),
                      _createVNode(FormItem, { name: "other_attributes.kpp" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps(field, {
                            "model-value": value,
                            placeholder: "123456789"
                          }), null, 16, ["model-value"]), [
                            [_directive_mask, ['#########']]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_57, [
                      _cache[29] || (_cache[29] = _createElementVNode("p", { class: "font-semibold mb-1" }, "ОКТМО", -1)),
                      _createVNode(FormItem, { name: "other_attributes.oktmo" }, {
                        default: _withCtx(({ field, value }) => [
                          _withDirectives(_createVNode(_component_el_input, _mergeProps(field, {
                            "model-value": value,
                            size: "large",
                            placeholder: "12345678"
                          }), null, 16, ["model-value"]), [
                            [_directive_mask, ['########']]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", null, [
                  _cache[30] || (_cache[30] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Наименование", -1)),
                  _createVNode(FormItem, { name: "other_attributes.name_income" }, {
                    default: _withCtx(({ field, value }) => [
                      _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]))
      ])
    ])
  ], 64))
}
}

})