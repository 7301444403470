<script lang="ts" setup>
import useSummaryNew from '@/05-Features/DeclarationSummary/model/useSummaryNew'
import { computed } from 'vue'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'

interface IProps {
  actions: Record<GoodsTypeName, string[]>
}
const props = defineProps<IProps>()
// const declarationComplete = computed(() =>
//   props.actions?.Declaration?.reduce((acc: boolean, item: string) => {
//     return acc && ['Transfer_resolved', 'Missing_resolved', 'Short_resolved'].includes(item)
//   }, true)
// )
// const stepStatus = computed(() => {
//   if (props.actions.Declaration?.includes('Transfer')) {
//     return 'Переводы с других счетов'
//   }
//   if (props.actions.Declaration?.includes('Missing')) {
//     return 'Нет данных о покупке'
//   }
//   if (props.actions.Declaration?.includes('Short')) {
//     return 'Незакрытые короткие позиции'
//   }
//   if (props.actions.Declaration?.includes('Personal_data')) {
//     return 'Персональные даннные'
//   }
//   if (props.actions.Declaration?.includes('Add_income')) {
//     return 'Дополнительные доходы'
//   }
//   if (props.actions.Declaration?.includes('Deduction')) {
//     return 'Вычеты'
//   }
//   if (props.actions.Cfr?.includes('Add_account')) {
//     return 'Указать данные по счету для ОДС'
//   }
//   if (props.actions.Cfr?.includes('Need_file_for_last_year')) {
//     return 'Необходимо догрузить файлы FF'
//   }
//   return 'ошибка определения статуса'
// })
const titleList = {
  additional_incomes: 'Дополнительные доходы',
  deductions: 'Вычеты',
  personal_data: 'Персональные данные',
}

const { summary, taxes15, taxes13 } = useSummaryNew()
</script>

<template>
  <!--  <div class="flex items-center mb-2">-->
  <!--    <div class="font-weight&#45;&#45;extra font-color&#45;&#45;deep-gray mr-2">Статус:</div>-->
  <!--    <span :class="declarationComplete ? 'font-color&#45;&#45;green' : 'font-color&#45;&#45;red'" class="font-weight&#45;&#45;extra">-->
  <!--      {{ declarationComplete ? 'Документы готовы' : stepStatus }}-->
  <!--    </span>-->
  <!--  </div>-->
  <div class="flex items-center font-color--deep-gray mb-2">
    <div class="mr-2 font-weight--extra">Налог 13%:</div>
    <div>{{ taxes13 }} ₽</div>
  </div>
  <div class="flex items-center font-color--deep-gray mb-2">
    <div class="mr-2 font-weight--extra">Налог 15%:</div>
    <div>{{ taxes15 }} ₽</div>
  </div>
  <div v-if="Object.keys(summary).length > 0">
    <div v-for="(item, index) in summary" :key="index + Date.now()" class="mb-2">
      <div v-for="(value, key) in item" :key="key">
        <div class="flex items-center font-color--deep-gray">
          <div class="mr-2 font-weight--extra">{{ titleList[key] }}:</div>
          <div>{{ key === 'personal_data' ? value : value.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
