<script setup lang="ts">
import { computed, defineExpose, ref } from 'vue'
import { boolean, object, string, array, number } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FieldArray, useFieldArray, useForm } from 'vee-validate'
import { IBrokerAccount, ICashFlow } from '@/entityes/invoice/cfr/cfr.type'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import usePolitic from '@/use/usePolitic'
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons-vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import { useCfrRepository } from '@/entityes/invoice/cfr/cfr.repository'
import { vMaska } from 'maska/vue'
import { onlyLatin } from '@/07-Shared/lib/maskHelper'

interface IEmits {
  (e: 'save', value: IBrokerAccount): void
  (e: 'update', value: IBrokerAccount): void
}
const emit = defineEmits<IEmits>()
const visible = ref(false)
const currentBrokerAccount = ref<IBrokerAccount | undefined>(undefined)
const { isAdmin } = usePolitic()
const { showBrokerAccount } = useCfrRepository()
const accountTypes = {
  non_bank_credit_org: 'Небанковская кредитная организация',
  life_insurer: 'Страховщик, осуществляющий деятельность по добровольному страхованию жизни',
  securities_professional: 'Профессиональный участник рынка ценных бумаг',
  trust_manager: 'Управляющий по договору доверительного управления имуществом',
  pension_fund: 'Негосударственный пенсионный фонд',
  investment_fund: 'Акционерный инвестиционный фонд',
  asset_manager: 'УК инвестиционного, паевого или пенсионного фонда',
  central_counterparty: 'Центральный контрагент',
  investment_partnership_manager: 'Управляющий товарищ инвестиционного товарищества',
  other_financial_org: 'Иная организация',
}
const schema = toTypedSchema(
  object({
    account_number: string().required('Это поле обязательно для заполнения'),
    account_type: string().required('Это поле обязательно для заполнения'),
    other_orgs_type: string().test('show rule', 'Поле является обязательным', (value, context) => {
      const type = context.from?.[0].value.account_type
      if (type !== 'bank') {
        return string().required('Это поле обязательно для заполнения').isValidSync(value)
      }
      return true
    }),
    broker_oksm_code: string().required('Это поле обязательно для заполнения'),
    broker_tax_payer_number: string(),
    broker_swift: string().test('show rule', 'Поле является обязательным', (value, context) => {
      const type = context.from?.[0].value.account_type
      if (type === 'bank') {
        return string().required('Это поле обязательно для заполнения').isValidSync(value)
      }
      return true
    }),
    opening_account_date: string().required('Это поле обязательно для заполнения'),
    closed_account_date: string(),
    common_account: boolean(),
    broker_name: string().required('Это поле обязательно для заполнения'),
    broker_address: string().required('Это поле обязательно для заполнения. Только латинскими символами'),
    cash_flows: array()
      .ensure()
      .of(
        object({
          addition: number(),
          at_begin: number(),
          at_end: number(),
          cash_flow_type: string(),
          currency_code: number(),
          subtraction: number(),
          other_asset_type: object({
            cash: boolean(),
            securities: boolean(),
            financial_derivatives: boolean(),
            participatory_interest_without_entity: boolean(),
            participatory_interest: boolean(),
            rights_of_claim: boolean(),
            other: boolean(),
          }),
        })
      ),
  })
)
const initial = computed(() => {
  return {
    account_number: currentBrokerAccount.value?.account_number || '',
    account_type: currentBrokerAccount.value?.account_type || 'bank',
    broker_oksm_code: currentBrokerAccount.value?.broker_oksm_code || '',
    broker_tax_payer_number: currentBrokerAccount.value?.broker_tax_payer_number || '',
    broker_swift: currentBrokerAccount.value?.broker_swift || '',
    opening_account_date: currentBrokerAccount.value?.opening_account_date || '',
    closed_account_date: currentBrokerAccount.value?.closed_account_date || '',
    broker_name: currentBrokerAccount.value?.broker_name || '',
    broker_address: currentBrokerAccount.value?.broker_address || '',
    common_account: currentBrokerAccount.value?.common_account || false,
    other_orgs_type: currentBrokerAccount.value?.common_account || '',
    cash_flows: cashFlowsInitial.value,
  }
})
const cashFlowsInitial = computed(() => {
  if (!!currentBrokerAccount.value) {
    return [...currentBrokerAccount.value?.cash_flows]
  } else {
    return [
      {
        addition: 0.0,
        at_begin: 0.0,
        at_end: 0.0,
        cash_flow_type: 'cash',
        currency_code: 840,
        subtraction: 0.0,
        other_asset_type: {
          cash: true,
          securities: false,
          financial_derivatives: false,
          participatory_interest_without_entity: false,
          participatory_interest: false,
          rights_of_claim: false,
          other: false,
        },
      },
    ]
  }
})
const { handleSubmit, values, setValues, validate, resetForm } = useForm({
  validationSchema: schema,
  initialValues: { ...initial.value },
})
const { fields, push, remove } = useFieldArray<ICashFlow>('cash_flows')
const accountTypeList = [
  {
    label: 'Банк',
    value: 'bank',
  },
  {
    label: 'Иная организация финансового рынка',
    value: 'broker',
  },
]
const loading = ref(false)

function replaceNullsWithEmptyStrings(obj: any): any {
  for (const key in obj) {
    if (obj[key] === null) {
      if (Object.keys(initial.value).includes(key)) {
        obj[key] = ''
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceNullsWithEmptyStrings(obj[key])
    }
  }
  return obj
}
function open(cfrId: string | number, accountId: string | number | undefined) {
  currentBrokerAccount.value = undefined
  if (!!accountId) {
    loading.value = true
    showBrokerAccount(cfrId, accountId)
      .then((res: IBrokerAccount) => {
        currentBrokerAccount.value = replaceNullsWithEmptyStrings({ ...res })
        setValues(currentBrokerAccount.value as IBrokerAccount)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        visible.value = true
        loading.value = false
      })
  } else {
    resetForm()
    visible.value = true
    loading.value = false
  }
}

function close() {
  visible.value = false
}

const confirm = handleSubmit((val) => {
  loading.value = true
  const res = Object.keys(val).reduce((acc, key) => {
    if (val[key] !== '') {
      acc[key] = val[key]
    }
    return acc
  }, {})
  if (!!currentBrokerAccount.value) {
    emit('update', Object.assign(res, currentBrokerAccount.value?.id) as IBrokerAccount)
  } else {
    emit('save', res as IBrokerAccount)
  }
})

defineExpose({ open, close })
</script>

<template>
  <el-dialog v-model="visible">
    <h3 class="mb-4">{{ currentBrokerAccount ? 'Редактировать счет' : 'Создать счет' }}</h3>

    <div v-loading="loading">
      <el-form class="font-color--gray" @submit.prevent="confirm" :validation-schema="schema">
        <div class="mb-4">
          <form-item class="mb-4" label="Название организации" name="broker_name" v-slot="{ value, onBlur, onInput }">
            <el-input
              :model-value="value"
              v-maska="onlyLatin"
              type="text"
              name="broker_name"
              @blur="onBlur"
              @input="onInput"
              placeholder="Interactive Brokers | Freedom Finance (только латинские буквы)"
            />
          </form-item>
          <form-item class="mb-4" label="Адрес организации" name="broker_address" v-slot="{ value, onBlur, onInput }">
            <el-input
              :model-value="value"
              type="text"
              name="broker_address"
              @blur="onBlur"
              @input="onInput"
              v-maska="onlyLatin"
              placeholder="Cape Town Lancaster street Gordons Bay (только латинские буквы)"
            />
          </form-item>
          <form-item class="mb-4" label="Номер счета" name="account_number" v-slot="{ value, onBlur, onInput }">
            <el-input :model-value="value" type="text" name="account_number" @blur="onBlur" @input="onInput" />
          </form-item>
          <FormItem class="mb-4" label="Тип организации" name="account_type" v-slot="{ value, onInput, onBlur }">
            <el-select
              :model-value="value"
              @update:model-value="onInput"
              filterable
              placeholder="Выберите тип организации"
              @blur="onBlur"
              name="account_type"
            >
              <el-option v-for="item in accountTypeList" :key="item.value" :value="item.value" :label="item.label" />
            </el-select>
          </FormItem>
          <FormItem
            class="mb-4"
            label="Тип иной организации"
            name="other_orgs_type"
            v-slot="{ value, onInput, onBlur }"
            v-if="values.account_type !== 'bank'"
          >
            <el-select
              :model-value="value"
              @update:model-value="onInput"
              filterable
              placeholder="Выберите тип иной организации"
              @blur="onBlur"
              name="other_orgs_type"
            >
              <el-option v-for="(value, key) in accountTypes" :key="key" :value="key" :label="value" />
            </el-select>
          </FormItem>
          <form-item
            class="mb-4"
            v-if="values.account_type === 'bank'"
            label="SWIFT или БИК"
            name="broker_swift"
            v-slot="{ value, onBlur, onInput }"
          >
            <el-input :model-value="value" type="text" name="broker_swift" @blur="onBlur" @input="onInput" />
          </form-item>
          <form-item label="Номер налогоплательщика" name="broker_tax_payer_number" v-slot="{ value, onBlur, onInput }">
            <el-input :model-value="value" type="text" name="broker_tax_payer_number" @blur="onBlur" @input="onInput" />
          </form-item>
          <FormItem class="mb-4" label="Код страны" name="broker_oksm_code" v-slot="{ value, onInput, onBlur }">
            <el-select
              :model-value="value"
              @update:model-value="onInput"
              filterable
              placeholder="Выберите код страны"
              @blur="onBlur"
            >
              <el-option
                v-for="item in useCountryDescriber()"
                :key="item.code"
                :value="item.code"
                :label="item.shortname"
              />
            </el-select>
          </FormItem>
          <div class="flex items-center">
            <form-item class="mr-4" label="Дата открытия счета" name="opening_account_date" v-slot="{ field, value }">
              <el-date-picker
                v-bind="field"
                :model-value="value"
                type="date"
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
                name="opening_account_date"
              />
            </form-item>
            <form-item label="Дата закрытия счета" name="closed_account_date" v-slot="{ field, value }">
              <el-date-picker
                v-bind="field"
                :model-value="value"
                type="date"
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
              />
            </form-item>
          </div>
        </div>
        <div>
          <form-item name="common_account" v-slot="{ value, field }">
            <Checkbox :value="value" v-bind="field"> Совместный счет </Checkbox>
          </form-item>
        </div>

        <div class="el-divider-purple my-8" />

        <FieldArray v-if="isAdmin" class="mb-4" name="cash_flows">
          <div v-for="(cash, idx) in fields" :key="cash.key">
            <div class="flex items-center justify-between mb-4">
              <div class="flex">
                <h3 class="text-dark-white">
                  {{ idx + 1 }}
                  {{
                    cash.value.other_asset_type.cash || cash.value.cash_flow_type === 'cash'
                      ? 'Движение денежных средств'
                      : 'Стоимость и виды иных финансовых активов'
                  }}
                </h3>
              </div>
              <el-button-group>
                <el-button
                  size="small"
                  :disabled="fields.length === 1"
                  :icon="RemoveFilled"
                  type="danger"
                  @click="remove(idx)"
                />
                <el-button
                  :icon="CirclePlusFilled"
                  size="small"
                  type="primary"
                  @click="
                    () =>
                      push({
                        addition: 0.0,
                        at_begin: 0.0,
                        at_end: 0.0,
                        cash_flow_type: 'cash',
                        currency_code: 840,
                        subtraction: 0.0,
                        other_asset_type: {
                          cash: true,
                          securities: false,
                          financial_derivatives: false,
                          participatory_interest_without_entity: false,
                          participatory_interest: false,
                          rights_of_claim: false,
                          other: false,
                        },
                      })
                  "
                />
              </el-button-group>
            </div>
            <div class="grid grid-rows-3 grid-cols-2 grid-flow-col gap-4">
              <form-item
                label="Зачислено"
                class="mb-2"
                :name="`cash_flows[${idx}].addition`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-input @update:model-value="onInput" :model-value="value" @blur="onBlur" />
              </form-item>
              <form-item
                label="Сумма на начало периода"
                class="mb-2"
                :name="`cash_flows[${idx}].at_begin`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-input @update:model-value="onInput" :model-value="value" @blur="onBlur" />
              </form-item>
              <FormItem
                class="mb-4"
                label="Код валюты"
                :name="`cash_flows[${idx}].currency_code`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-select
                  :model-value="value"
                  @update:model-value="onInput"
                  filterable
                  placeholder="Выберите валюту"
                  @blur="onBlur"
                >
                  <el-option
                    v-for="item in useCountryDescriber()"
                    :key="item.code"
                    :value="item.code"
                    :label="item.shortname"
                  />
                </el-select>
              </FormItem>
              <form-item
                label="Списано"
                class="mb-2"
                :name="`cash_flows[${idx}].subtraction`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-input @update:model-value="onInput" :model-value="value" @blur="onBlur" />
              </form-item>
              <form-item
                label="Сумма на конец периода"
                class="mb-2"
                :name="`cash_flows[${idx}].at_end`"
                v-slot="{ value, onInput, onBlur }"
              >
                <el-input @update:model-value="onInput" :model-value="value" @blur="onBlur" />
              </form-item>
              <FormItem label="Тип" :name="`cash_flows[${idx}].cash_flow_type`" v-slot="{ value, onInput, onBlur }">
                <el-select
                  :model-value="value"
                  @update:model-value="onInput"
                  filterable
                  placeholder="Выберите тип"
                  @blur="onBlur"
                >
                  <el-option v-for="item in ['cash', 'other']" :key="item" :value="item" :label="item" />
                </el-select>
              </FormItem>
            </div>
            <div class="grid grid-flow-col grid-rows-3">
              <form-item class="mb-2" :name="`cash_flows[${idx}].other_asset_type.cash`" v-slot="{ value, field }">
                <Checkbox :value="value" v-bind="field">cash</Checkbox>
              </form-item>
              <form-item
                class="mb-2"
                :name="`cash_flows[${idx}].other_asset_type.securities`"
                v-slot="{ value, field }"
              >
                <Checkbox :value="value" v-bind="field">securities</Checkbox>
              </form-item>
              <form-item
                class="mb-2"
                :name="`cash_flows[${idx}].other_asset_type.financial_derivatives`"
                v-slot="{ value, field }"
              >
                <Checkbox :value="value" v-bind="field">financial_derivatives</Checkbox>
              </form-item>
              <form-item
                class="mb-2"
                :name="`cash_flows[${idx}].other_asset_type.participatory_interest_without_entity`"
                v-slot="{ value, field }"
              >
                <Checkbox :value="value" v-bind="field">participatory_interest_without_entity</Checkbox>
              </form-item>
              <form-item
                class="mb-2"
                :name="`cash_flows[${idx}].other_asset_type.participatory_interest`"
                v-slot="{ value, field }"
              >
                <Checkbox :value="value" v-bind="field">participatory_interest</Checkbox>
              </form-item>
              <form-item
                class="mb-2"
                :name="`cash_flows[${idx}].other_asset_type.rights_of_claim`"
                v-slot="{ value, field }"
              >
                <Checkbox :value="value" v-bind="field">rights_of_claim</Checkbox>
              </form-item>
              <form-item class="mb-2" :name="`cash_flows[${idx}].other_asset_type.other`" v-slot="{ value, field }">
                <Checkbox :value="value" v-bind="field">other</Checkbox>
              </form-item>
            </div>
            <div class="el-divider-purple my-8" />
          </div>
        </FieldArray>

        <el-button :loading="loading" native-type="submit" type="primary">Сохранить</el-button>
      </el-form>
    </div>
  </el-dialog>
</template>

<style scoped lang="sass"></style>
