import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = { class: "mr-4" }

import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { cloneDeep } from 'lodash'
import { useForm } from 'vee-validate'
import { UnwrapNestedRefs, watch } from 'vue'
import { object, string, number } from 'yup'
import { setTouchedHelper } from '@/04-Widgets/AdditionelIncome/lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialLosses',
  props: {
    item: {},
    yearToCalc: {}
  },
  emits: ["update-item"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const schema = object({
  currency: string().default('RUB'),
  other_attributes: object({
    cb: number().required().default(0),
    pfi: number().required().default(0),
  }),
})
const validationSchema = toTypedSchema(schema)
setTouchedHelper(props.item)

const { values, validate, errorBag } = useForm({
  validationSchema,
  validateOnMount: true,
  initialValues: Object.assign({}, { ...props.item.item }),
})
async function prepareItem() {
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-semibold mb-4" }, "Задекларированные убытки за предыдущие налоговые периоды", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Убытки по операциям с ЦБ (в руб.)", -1)),
        _createVNode(FormItem, { name: "other_attributes.cb" }, {
          default: _withCtx(({ field, value }) => [
            _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Убытки по операциям с ПФИ (в руб.)", -1)),
        _createVNode(FormItem, { name: "other_attributes.pfi" }, {
          default: _withCtx(({ field, value }) => [
            _createVNode(_component_el_input, _mergeProps({ "model-value": value }, field), null, 16, ["model-value"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})