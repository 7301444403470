<script lang="ts" setup>
import useGoodsDescription from '@/describers/GoodsDescriber'
import { computed, ref, defineAsyncComponent, watch, reactive } from 'vue'
import useGtagManager from '@/use/useGtagManager'
import usePolitic from '@/use/usePolitic'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
// import { GoodsPreviewMsg } from '@/05-Features/ResultMsg'
import ResultGoodCard from '@/07-Shared/ui/ResultGoodCard.vue'
import DeclarationSummary from '@/05-Features/DeclarationSummary/ui/DeclarationSummary.vue'
import DeclarationCorrectionNumberInput from '@/05-Features/DeclarationVersionSet/ui/DeclarationCorrectionNumberInput.vue'
import { useRouter } from 'vue-router'
import GoodsResultFiles from '@/05-Features/ResultFiles/ui/GoodsResultFiles.vue'
import CfrOnlineResults from '@/05-Features/CfrOnlineResults/ui/CfrOnlineResults.vue'
import { useCfr } from '@/04-Widgets/ResultStep/api/useCfr.request'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import img from '@/07-Shared/ui/ImgDescriber'
import GuruLogo from '@/assets/guru_logo.svg'
import fileLoader from '@/06-Entities/File/model/FileLoader'
import { ElNotification } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import { FileItem } from '@/06-Entities/File'
import { ILoaderDescriber, PreProcessingFiles } from '@/entityes/invoice/file/loader/loader.type'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
const SidebarLoader = defineAsyncComponent(() => import('@/05-Features/SidebarLoader/ui/SidebarLoader.vue'))
interface IProps {
  id: string | number
}
const props = defineProps<IProps>()
const invoiceStore = useInvoiceStore()
const { getInvoiceList, getInvoice } = useInvoiceRepository()
const { goodsList } = useGoodsDescription()
const { event } = useGtagManager()
const { isAdmin, isPartner } = usePolitic()
const router = useRouter()
const declarationStore = useDeclarationStore()
const { getCfr } = useCfr()
const { getDeclaration, loadResultFilesForDeclaration } = useDeclarationRepository()
const goods = computed(() => invoiceStore?.invoice?.goods)
const renderGoods = computed(() => {
  if (goods.value) {
    return goodsList.filter((p) => Object.keys(goods?.value || {}).includes(p.name))
  }
})
const actions = computed(() => invoiceStore.invoice?.status.actions_for_goods)
const additional = computed(() => invoiceStore.invoice?.additional)
const declarationId = computed(() => {
  if (goods.value) {
    return goods.value?.Declaration
  }
})
const invoiceVideo = ref<typeof NgInvoiceVideo>()
const hasOnlineCfr = ref(false)
const showSidebar = ref(false)
const declarationLoader = {
  title: 'для декларации',
  text: 'Нажмите или перетащите файлы для загрузки итоговых файлов для декларации',
  serverTag: 'declarations',
  name: 'Declaration',
  fileFormat: /.*\.(zip|xlsx|dc2|dc1|xml|pdf)$/,
  img: img.csv,
  logo: GuruLogo,
}
const cfrLoader = {
  title: 'для ОДС',
  text: 'Нажмите или перетащите файлы для загрузки итоговых файлов для ОДС',
  serverTag: 'cfrs',
  name: 'Cfr',
  fileFormat: /.*\.(zip)$/,
  img: img.csv,
  logo: GuruLogo,
}
const currentLoader = ref<ILoaderDescriber>(declarationLoader)
const loader = ref<any>(fileLoader(currentLoader.value))

function toggleResultFiles(val: { goodName: string; show: boolean }) {
  const good = goodsList.find((good) => good.name === val.goodName)
  if (good && good.showResultFiles) {
    good.showResultFiles.value = val.show
  }
}
function openLoader(goodName: string) {
  if (goodName === 'Declaration') currentLoader.value = declarationLoader
  if (goodName === 'Cfr') currentLoader.value = cfrLoader
  showSidebar.value = true
}
function sendFiles(goodName: string) {
  const tag = goodName === 'Declaration' ? 'declarations' : 'cfrs'
  const body = {
    [tag]: {
      ...loader.value.localFiles,
    },
  } as PreProcessingFiles
  loadResultFilesForDeclaration(props.id, body)
    .then(() => {
      ElNotification({
        title: 'Файлы декларации',
        message: 'успешно обновлены',
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `при загрузке файлов ${err}`,
        type: 'error',
      })
    })
}
if (isAdmin?.value) {
  event('internal_traffic')
}
// TODO this is a very strange decision becouse asking for a list of orders to check the video display is very strange
getInvoiceList().then((res) => {
  if (res?.invoices?.length === 1 && !isAdmin.value && !isPartner.value) {
    invoiceVideo.value?.openVideoModal()
  }
})
const { initAsync } = useAsyncInvoiceReload()
const loading = ref(false)
const showCfrResults = computed(() => isAdmin.value || !invoiceStore.invoice?.status.blocked)
function init() {
  loading.value = true
  getInvoice(props.id)
    .then((res) => {
      return initAsync(res)
    })
    .then((res) => {
      if (res.goods?.Declaration) {
        getDeclaration(res.goods.Declaration)
      }
      if (res.goods?.Cfr) {
        getCfr(res.goods.Cfr)
      }
      loading.value = false
    })
}
init()
watch(currentLoader, (currentLoader) => {
  loader.value = fileLoader(currentLoader)
})
</script>

<template>
  <div class="container-list">
    <div class="flex">
      <h1 class="mb-4 mr-4">Итоговые документы</h1>
      <ng-invoice-video
        ref="invoiceVideo"
        :show-button="true"
        :video-link="'https://www.youtube.com/embed/iR-A0IPepn4?si=UOfTDNqyxCjKZQux'"
      />
    </div>
    <!--    <goods-preview-msg-->
    <!--      :good-id="declarationId"-->
    <!--      :invoice-id="id"-->
    <!--      v-if="goods && declarationId !== undefined && additional !== undefined"-->
    <!--      :additional="additional"-->
    <!--    />-->
    <div v-loading="loading" class="step-box no-select mb-4" v-for="good in renderGoods" :key="good.name">
      <ResultGoodCard :good-name="good.name" @show-files="toggleResultFiles">
        <template v-slot:left>
          <h2 class="mb-4">{{ good.title }} {{ invoiceStore.invoice?.year_to_calc }}</h2>
          <div v-if="good.name === 'Declaration' && declarationStore.declaration">
            <declaration-summary v-if="!!invoiceStore.invoice" :actions="actions" />
            <declaration-correction-number-input
              v-if="declarationStore.declaration"
              :declaration="declarationStore.declaration"
            />
          </div>
          <div v-if="good.name === 'Cfr'">
            <el-button
              class="mb-4"
              size="small"
              type="primary"
              v-if="goods && actions?.['Cfr']?.includes('Need_file_for_last_year')"
              @click="router.push({ name: 'file_in_invoice' })"
            >
              Загрузить
            </el-button>
          </div>
        </template>
        <template v-slot:right>
          <div class="flex flex-col justify-end p-4 h-[76%]" v-if="isAdmin && good.showResultFiles?.value === false">
            <div
              v-if="loader.localFiles.files.length > 0 && loader.config.name === good.name"
              class="flex justify-between items-center p-4 mb-4 step-box bg-green"
            >
              <el-scrollbar class="pr-8" :max-height="100" always>
                <FileItem
                  v-for="name in loader.localFiles.file_names"
                  :name="name"
                  :key="name"
                  @close="loader.onRemoveLocalFile"
                />
              </el-scrollbar>
              <el-button @click="sendFiles(good.name)" size="small" type="info">Отправить файлы</el-button>
            </div>
            <div class="flex justify-end">
              <el-button
                :icon="UploadFilled"
                round
                plain
                @click="openLoader(good.name)"
                v-if="goods && good.name === 'Declaration'"
                class="max-w-[400px]"
              >
                Заменить итоговые файлы
              </el-button>
            </div>
            <sidebar-loader
              :loader-describer="loader"
              v-model:show-sidebar="showSidebar"
              @readed-file="loader.onUpdateFilesEvent"
              @delete-local-file="loader.onRemoveLocalFile"
              @delete-server-file="loader.onAddDeleteFile"
              @undo-delete-file="loader.undoDeleteFiles"
            />
          </div>
          <GoodsResultFiles
            v-if="good.showResultFiles?.value && goods?.[good.name as GoodsTypeName]"
            :good-name="good.name"
            :good-id="goods[good.name as GoodsTypeName]"
          />
        </template>
        <template v-if="good.name === 'Cfr'" v-slot:bottom>
          <div class="p-4" v-if="good.showResultFiles?.value && showCfrResults">
            <cfr-online-results v-if="goods?.['Cfr']" :good-id="goods['Cfr']" />
          </div>
        </template>
      </ResultGoodCard>
    </div>
  </div>
</template>

<style scoped lang="sass">
.pzdc
  animation: 1s ease infinite pulse
@keyframes pulse
  0%
    transform: scale(1)
  25%
    transform: scale(1.115)
  50%
    transform: scale(1)
</style>
