import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mr-4" }
const _hoisted_4 = { class: "flex flex-wrap mb-4 px-4" }
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "flex items-center mb-1" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = { class: "flex items-center mb-1" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "mb-4 px-4"
}
const _hoisted_12 = { class: "flex items-center mb-1" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "bg-gray p-4" }
const _hoisted_15 = { class: "mb-4" }
const _hoisted_16 = { class: "flex items-center mb-1" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "flex items-center mb-1" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "flex flex-wrap" }
const _hoisted_22 = {
  key: 0,
  class: "mr-6 p-4"
}
const _hoisted_23 = { class: "flex items-center mb-1" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = {
  key: 1,
  class: "mr-6 p-4"
}
const _hoisted_26 = { class: "flex items-center mb-1" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 2,
  class: "p-4"
}
const _hoisted_29 = { class: "flex items-center mb-1" }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "bg-gray" }
const _hoisted_32 = {
  key: 0,
  class: "p-4"
}
const _hoisted_33 = { class: "flex flex-wrap mb-4" }
const _hoisted_34 = { class: "mr-8" }
const _hoisted_35 = { class: "flex items-center mb-1" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = { class: "flex items-center mb-1" }
const _hoisted_38 = ["innerHTML"]
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { class: "el-divider-purple my-2" }
const _hoisted_41 = {
  key: 1,
  class: "p-4"
}
const _hoisted_42 = { class: "flex items-center flex-wrap mb-4" }
const _hoisted_43 = { class: "mr-8" }
const _hoisted_44 = { class: "flex items-center mb-1" }
const _hoisted_45 = ["innerHTML"]
const _hoisted_46 = { class: "flex items-center mb-1" }
const _hoisted_47 = ["innerHTML"]
const _hoisted_48 = ["innerHTML"]

import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import { UnwrapNestedRefs, watch } from 'vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import { bool, number, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import Checkbox from '@/07-Shared/ui/Checkbox.vue'
import usePropertyDeductionDescriber from '@/04-Widgets/DeductionWidget/model/usePropertyDeductionDescriber'
import { setTouchedHelper } from '@/04-Widgets/AdditionelIncome/lib/setTouchedHelper'

interface IProps {
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  yearToCalc: number
  helpDescriber: any
  admin: any
  goodId: string | number
}
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
  (e: 'delete'): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PropertyDeduction',
  props: {
    item: {},
    yearToCalc: {},
    helpDescriber: {},
    admin: {},
    goodId: {}
  },
  emits: ["update-item", "delete"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { agreement_type, property_type, owner_type, purchase_type } = usePropertyDeductionDescriber()
const cadastr = ref(false)
const baseRefund = ref(true)
const mortgageRefund = ref(false)

const schema = object({
  sum: number().test('sum-test', 'Поле является обязательным', (value) => {
    if (baseRefund.value) {
      return string().required().isValidSync(value)
    }
    return true
  }),
  other_attributes: object({
    mortgage_sum: number().test('mortgage-test', 'Поле является обязательным', (value) => {
      if (mortgageRefund.value) {
        return string().required().isValidSync(value)
      }
      return true
    }),
    last_year_build_deduction: string().test(
      'last_year_build_deduction-test',
      'Поле является обязательным',
      (value) => {
        if (baseRefund.value) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    last_year_mortgage_deduction: string().test(
      'last_year_mortgage_deduction-test',
      'Поле является обязательным',
      (value) => {
        if (mortgageRefund.value) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    agreement_type: string().required('Поле является обязательным'),
    date_deed_flat: string().test('date_deed_flat_test', 'Поле является обязательным', (value, context) => {
      const a_type = context.from?.[1].value.agreement_type
      if (a_type === 'investment') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    date_registration_house: string().test(
      'date_registration_house_test',
      'Поле является обязательным',
      (value, context) => {
        const a_type = context.from?.[1].value.agreement_type
        if (a_type === 'buy_sell') {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    date_registration_land: string().test(
      'date_registration_house_test',
      'Поле является обязательным',
      (value, context) => {
        const a_type = context.from?.[1].value.agreement_type
        const p_type = [
          'land_individual_construction',
          'land_with_purchased_residential',
          'residential_building_with_land',
        ].includes(context.from?.[1].value.real_estate_type)
        if (a_type === 'buy_sell' && p_type) {
          return string().required().isValidSync(value)
        }
        return true
      }
    ),
    real_estate_type: string().required('Поле является обязательным'),
    purchase_type: string(),
    owner_type: string().required('Поле является обязательным'),
    cadastr_number: string().test('cadatr-number-test', 'Поле является обязательным', (value) => {
      if (cadastr.value) {
        return string().required().isValidSync(value)
      }
      return true
    }),
    address: string().required('Поле является обязательным'),
    simplified_build: bool(),
    simplified_mortgage: bool(),
  }),
})
const validationSchema = toTypedSchema(schema)
const { values, validate, setValues } = useForm({
  validationSchema,
  initialValues: Object.assign(
    {
      other_attributes: {
        purchase_type: '',
        simplified_build: false,
        simplified_mortgage: false,
      },
    },
    {
      ...props.item.item,
    }
  ),
  validateOnMount: true,
})
setTouchedHelper(props.item)

function checkPropertySum() {
  if (values.sum && values.sum > 2000000) {
    ElMessageBox({
      title: 'Вычет по покупке или строительства недвижимости',
      message: 'Получить вычет можно только с суммы не более чем 2 000 000 ₽',
      type: 'success',
      confirmButtonText: 'Понятно',
    }).then(() => {
      setValues({
        sum: 2000000,
      })
    })
  }
}
async function prepareItem() {
  const valid = (await validate()).valid
  emit('update-item', { ...values, valid })
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)

watch(baseRefund, (val) => {
  if (!val && !mortgageRefund.value) baseRefund.value = true
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "font-semibold mb-1" }, "Тип вычета", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(Checkbox, {
              value: baseRefund.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((baseRefund).value = $event))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Основной имущественный ")
              ])),
              _: 1
            }, 8, ["value"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(Checkbox, {
              value: mortgageRefund.value,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((mortgageRefund).value = $event))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Проценты по ипотеке ")
              ])),
              _: 1
            }, 8, ["value"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Наименование объекта", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.real_estate_type
                }, null, 8, _hoisted_7)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "other_attributes.real_estate_type" }, {
            default: _withCtx(({ value, onBlur, onInput }) => [
              _createVNode(_component_el_select, {
                placeholder: "Выберите наименование объекта",
                class: "min-w-[220px]",
                "model-value": value,
                "onUpdate:modelValue": onInput,
                onBlur: onBlur,
                filterable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(property_type), (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.type,
                      value: item.type,
                      label: item.label
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex items-center mb-1.5" }, [
            _createElementVNode("p", { class: "font-semibold mr-1" }, "Способ приобретения")
          ], -1)),
          _createVNode(FormItem, { name: "other_attributes.agreement_type" }, {
            default: _withCtx(({ value, onBlur, onInput }) => [
              _createVNode(_component_el_select, {
                placeholder: "Выберите способ приобретения",
                class: "min-w-[220px]",
                "model-value": value,
                "onUpdate:modelValue": onInput,
                onBlur: onBlur,
                filterable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(agreement_type), (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.type,
                      value: item.type,
                      label: item.label
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_9, [
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Признак налогоплательщика", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.owner_type
                }, null, 8, _hoisted_10)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "other_attributes.owner_type" }, {
            default: _withCtx(({ value, onBlur, onInput }) => [
              _createVNode(_component_el_select, {
                placeholder: "Выберите признак налогоплательщика",
                class: "min-w-[220px]",
                "model-value": value,
                "onUpdate:modelValue": onInput,
                onBlur: onBlur,
                filterable: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(owner_type), (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.type,
                      value: item.type,
                      label: item.label
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["model-value", "onUpdate:modelValue", "onBlur"])
            ]),
            _: 1
          })
        ]),
        (
          _unref(values).other_attributes?.real_estate_type === 'residential' ||
          _unref(values).other_attributes?.real_estate_type === 'residential_building_with_land'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Способ приобретения дома", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.estimate
                    }, null, 8, _hoisted_13)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.purchase_type" }, {
                default: _withCtx(({ value, onInput }) => [
                  _createVNode(_component_el_select, {
                    placeholder: "Выберите способ приобретения дома",
                    class: "min-w-[220px]",
                    "model-value": value,
                    "onUpdate:modelValue": onInput,
                    filterable: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(purchase_type), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.type,
                          value: item.type,
                          label: item.label
                        }, null, 8, ["value", "label"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["model-value", "onUpdate:modelValue"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Место нахождения", -1)),
            _createVNode(QuestionPopover, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.helpDescriber?.address
                }, null, 8, _hoisted_17)
              ]),
              _: 1
            })
          ]),
          _createVNode(FormItem, { name: "other_attributes.address" }, {
            default: _withCtx(({ field, value }) => [
              _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_el_radio_group, {
            modelValue: cadastr.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((cadastr).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, { value: true }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Кадастровый номер")
                ])),
                _: 1
              }),
              _createVNode(_component_el_radio, { value: false }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Номер отсутствует")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        (cadastr.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Кадастровый номер", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.cadastr_number
                    }, null, 8, _hoisted_20)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, {
                class: "flex",
                name: "other_attributes.cadastr_number"
              }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_21, [
        (_unref(values).other_attributes?.agreement_type === 'investment')
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата акта о передаче квартиры, комнаты или доли в них", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.date_deed_flat
                    }, null, 8, _hoisted_24)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.date_deed_flat" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY",
                    class: "mr-2 min-w-[300px]"
                  }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(values).other_attributes?.agreement_type === 'buy_sell')
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата регистрации права собственности на жилой дом, квартиру, комнату", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.date_registration_house
                    }, null, 8, _hoisted_27)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.date_registration_house" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY",
                    class: "mr-2 min-w-[300px]"
                  }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
          _unref(values).other_attributes?.agreement_type === 'buy_sell' &&
          [
            'land_individual_construction',
            'land_with_purchased_residential',
            'residential_building_with_land',
          ].includes(_unref(values)?.other_attributes?.real_estate_type as string)
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _cache[16] || (_cache[16] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Дата регистрации права собственности на земельный участок", -1)),
                _createVNode(QuestionPopover, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.helpDescriber?.date_registration_house
                    }, null, 8, _hoisted_30)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(FormItem, { name: "other_attributes.date_registration_land" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(_component_el_date_picker, _mergeProps(field, {
                    "model-value": value,
                    type: "date",
                    placeholder: "Выберите дату",
                    format: "DD.MM.YYYY",
                    "value-format": "DD.MM.YYYY",
                    class: "mr-2 min-w-[300px]"
                  }), null, 16, ["model-value"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_31, [
      (baseRefund.value || (baseRefund.value && mortgageRefund.value))
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _cache[20] || (_cache[20] = _createElementVNode("p", { class: "font-semibold mb-4" }, "Основной имущественный вычет", -1)),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _cache[17] || (_cache[17] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Стоимость объекта (доли)", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.sum
                      }, null, 8, _hoisted_36)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "sum" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, {
                      "model-value": value,
                      placeholder: "не более 2 000 000 ₽",
                      onFocusout: checkPropertySum
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Вычет за предыдущие годы по основному имущественному вычету", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.last_year_build_deduction
                      }, null, 8, _hoisted_38)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.last_year_build_deduction" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(FormItem, { name: "other_attributes.simplified_build" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(Checkbox, _mergeProps({ value: value }, field), {
                    default: _withCtx(() => [
                      _cache[19] || (_cache[19] = _createTextVNode(" Вычет был предоставлен в упрощенном порядке ")),
                      _createVNode(QuestionPopover, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: _ctx.helpDescriber?.simplified
                          }, null, 8, _hoisted_39)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040, ["value"])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_40, null, 512), [
        [_vShow, mortgageRefund.value && baseRefund.value]
      ]),
      (mortgageRefund.value || (baseRefund.value && mortgageRefund.value))
        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
            _cache[24] || (_cache[24] = _createElementVNode("p", { class: "font-semibold mb-4" }, "Проценты по ипотеке", -1)),
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _cache[21] || (_cache[21] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Проценты по кредитам за все годы", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.mortgage_sum
                      }, null, 8, _hoisted_45)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.mortgage_sum" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, {
                      "model-value": value,
                      placeholder: "123456",
                      onFocusout: checkPropertySum
                    }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[22] || (_cache[22] = _createElementVNode("p", { class: "font-semibold mr-1" }, "Вычет за предыдущие годы по процентам по ипотеке", -1)),
                  _createVNode(QuestionPopover, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        innerHTML: _ctx.helpDescriber?.last_year_mortgage_deduction
                      }, null, 8, _hoisted_47)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(FormItem, { name: "other_attributes.last_year_mortgage_deduction" }, {
                  default: _withCtx(({ field, value }) => [
                    _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(FormItem, { name: "other_attributes.simplified_mortgage" }, {
                default: _withCtx(({ field, value }) => [
                  _createVNode(Checkbox, _mergeProps({ value: value }, field), {
                    default: _withCtx(() => [
                      _cache[23] || (_cache[23] = _createTextVNode(" Вычет был предоставлен в упрощенном порядке ")),
                      _createVNode(QuestionPopover, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: _ctx.helpDescriber?.simplified
                          }, null, 8, _hoisted_48)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1040, ["value"])
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})