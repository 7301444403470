import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-between mb-4"
}
const _hoisted_4 = { class: "font-weight--extra" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = { class: "flex justify-between items-center" }
const _hoisted_9 = { class: "flex flex-wrap" }
const _hoisted_10 = { class: "flex flex-wrap" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { key: 0 }

import { shallowRef, ref, computed } from 'vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import { ElNotification } from 'element-plus'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import TinkoffForm from '@/06-Entities/AdminInvoiceRemote/ui/tinkoffForm.vue'
import fileDownload from 'js-file-download'
import { SuccessFilled, Refresh, UploadFilled } from '@element-plus/icons-vue'
import { ITBankQuestionnaireRequest } from '@/entityes/invoice/TBankQuestionnaire/t_bank_questionnaire.type'
import { JsonViewer } from 'vue3-json-viewer'


export default /*@__PURE__*/_defineComponent({
  __name: 'TBankRemote',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const { getTBankPremiumStatus, aliveChat, setTBankPremiumStatus, getTBankForm, getAdditionalFile, sendFilesToTBank } =
  useInvoiceRemoteAdminRequest()
const tinkoffPremiumState = ref('')
const tinkoffForm = shallowRef({})
const additionalFilesTinkoff = computed(() => invoiceStore.invoice && invoiceStore.invoice.additional_incomes_files)
const isLoading = ref(false)
const invoiceAsync = computed(() => invoiceStore.invoice && invoiceStore.invoice.status.async)
const showJson = ref(false)

const premiumStatuses = [
  {
    rus: 'Анкета не заполнена',
    eng: 'Draft',
    method: undefined,
  },
  {
    rus: 'Анкета заполнена',
    eng: 'New',
    method: undefined,
  },
  {
    rus: 'Анализ',
    eng: 'Preprocess',
    method: 'start_review',
  },
  {
    rus: 'Требует оплаты',
    eng: 'PendingPayment',
    method: 'pending_payment',
  },
  {
    rus: 'Оплачен',
    eng: 'PaymentDone',
    method: 'payment_done',
  },
  {
    rus: 'Взят в работу',
    eng: 'PartnerGeneratingReportData',
    method: 'start_work',
  },
  {
    rus: 'Работа возобновлена',
    eng: 'PartnerInProgress',
    method: 'resume_work',
  },
  {
    rus: 'Уточнения',
    eng: 'Clarification',
    method: 'clarification',
  },
  {
    rus: 'Выполнена',
    eng: 'Acknowledge',
    method: 'acknowledge',
  },
  {
    rus: 'Завершена',
    eng: 'Ready',
    method: 'ready',
  },
  {
    rus: 'Отклонен',
    eng: 'Declined',
    method: 'decline',
  },
  {
    rus: 'Отменен',
    eng: 'Cancelled',
    method: 'cancel',
  },
]
function handleStatusRes(res: Record<string, string>) {
  if (Object.keys(res).includes('errorCode')) {
    ElNotification({
      title: 'Ошибка',
      message: `изменения статуса ${res.errorMessage}`,
      type: 'error',
    })
  } else {
    ElNotification({
      title: 'Успешное',
      message: `изменение статуса на ${premiumStatuses.find((i) => i.eng === res.status)?.rus}`,
      type: 'success',
    })
  }
}
function setStatus(method: string) {
  if (!!invoiceStore.invoice && method) {
    setTBankPremiumStatus(invoiceStore.invoice.id, { method }).then((res: any) => {
      handleStatusRes(res)
      if (!!invoiceStore.invoice) getTBankPremiumStatus(invoiceStore.invoice.id)
    })
  }
}
function pingChat() {
  aliveChat(invoiceStore.invoice.id)
    .then(() => {
      ElNotification({
        title: 'Успех',
        message: `чат жив!!!`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `произошла какая-то ошибка. Вот такая например ${err}`,
        type: 'error',
      })
    })
}
function downLoadFile(fileId: number, fileName: string) {
  getAdditionalFile(fileId)
    .then((res) => {
      fileDownload(res, fileName)
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `произошла ошибка скачивания файла. ${err}`,
        type: 'error',
      })
    })
}
function sendFiles() {
  sendFilesToTBank(invoiceStore.invoice.id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `отправлен запрос на передачу итоговых документов.`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `отправки запроса. ${err}`,
        type: 'success',
      })
    })
}

if (!Object.keys(invoiceStore.invoice?.goods).includes('Consultation')) {
  getTBankForm(invoiceStore.invoice.id)
    .then((res: ITBankQuestionnaireRequest) => {
      Object.keys(res?.form).forEach((key: string) => {
        tinkoffForm.value[key] = res?.form[key]
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения анкеты ${err}`,
        type: 'error',
      })
    })
}
if (invoiceStore.invoice?.email === 'external_premium@tinkoff.tinkoff') {
  getTBankPremiumStatus(invoiceStore.invoice.id)
    .then((res: any) => {
      tinkoffPremiumState.value += res?.status
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения статуса заявки TBank premium. ${err}`,
        type: 'error',
      })
    })
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, {
    gutter: 40,
    key: tinkoffPremiumState.value,
    class: "flex"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        lg: 6,
        class: "right-border"
      }, {
        default: _withCtx(() => [
          (_unref(declarationStore).declaration)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(TinkoffForm, {
                  questionnaire: tinkoffForm.value,
                  key: tinkoffForm.value,
                  declaration: _unref(declarationStore).declaration
                }, null, 8, ["questionnaire", "declaration"])),
                _createVNode(_component_el_button, {
                  round: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showJson.value = !showJson.value))
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("show json")
                  ])),
                  _: 1
                }),
                (showJson.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_unref(JsonViewer), {
                        value: tinkoffForm.value,
                        copyable: "",
                        boxed: true
                      }, null, 8, ["value"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        lg: 8,
        class: "right-border"
      }, {
        default: _withCtx(() => [
          (tinkoffPremiumState.value !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Статус: ")),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(premiumStatuses.find((i) => i.eng === tinkoffPremiumState.value)?.rus), 1)
                ]),
                _createVNode(_component_el_popover, {
                  placement: "right",
                  width: 400,
                  trigger: "click"
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      size: "small",
                      round: "",
                      type: "primary"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Изменить ")
                      ])),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(premiumStatuses, (status) => {
                      return _createElementVNode("div", {
                        onClick: ($event: any) => (setStatus(status.method)),
                        key: status.eng,
                        class: "card-hover p-4 border cursor-point"
                      }, [
                        _createVNode(_component_el_button, {
                          disabled: status.eng === 'New' || status.eng === 'Draft',
                          type: status.eng === tinkoffPremiumState.value ? 'success' : 'text',
                          link: ""
                        }, {
                          default: _withCtx(() => [
                            (status.eng === tinkoffPremiumState.value)
                              ? (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 0,
                                  class: "mr-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SuccessFilled))
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 1,
                                  class: "mr-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_SwitchButton)
                                  ]),
                                  _: 1
                                })),
                            _createTextVNode(" " + _toDisplayString(status.rus), 1)
                          ]),
                          _: 2
                        }, 1032, ["disabled", "type"])
                      ], 8, _hoisted_5)
                    }), 64))
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, "Этот заказ из категории Private. Анкету можно посмотреть в соседней вкладке Анкета Tinkoff JSON")),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1)),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mb-4" }, "Отправить итоговые документы", -1)),
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "dark",
              content: "если вы уже загрузили декларацию в pdf, то нажмите кнопку отправить",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  loading: isLoading.value,
                  type: "primary",
                  size: "small",
                  onClick: sendFiles,
                  icon: _unref(UploadFilled),
                  round: "",
                  disabled: isLoading.value && invoiceAsync.value
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" отправить ")
                  ])),
                  _: 1
                }, 8, ["loading", "icon", "disabled"])
              ]),
              _: 1
            })
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1)),
          _createElementVNode("div", _hoisted_8, [
            _cache[7] || (_cache[7] = _createElementVNode("p", null, "Оживить чат:", -1)),
            _createVNode(_component_el_button, {
              icon: _unref(Refresh),
              type: "danger",
              size: "small",
              round: "",
              onClick: pingChat
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" встань и иди ")
              ])),
              _: 1
            }, 8, ["icon"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { lg: 10 }, {
        default: _withCtx(() => [
          _cache[11] || (_cache[11] = _createElementVNode("h4", null, "Дополнительные файлы", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_scrollbar, {
              height: additionalFilesTinkoff.value.length > 16 ? '300px' : ''
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(additionalFilesTinkoff.value, (file) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: file.id,
                      class: "mb-4 mr-2"
                    }, [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "dark",
                        content: `Скачать ${file.name}`,
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            onClick: ($event: any) => (downLoadFile(file.id, file.name)),
                            class: "p-2 bg-purple cursor-point button--text-hover"
                          }, _toDisplayString(file.name), 9, _hoisted_11)
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["height"])
          ]),
          (additionalFilesTinkoff.value.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[10] || (_cache[10] = [
                _createElementVNode("p", null, "Дополнительные файлы отсутствуют", -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})