import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center"
}

import { computed } from 'vue'
import { IDeclaration } from '@/entityes/invoice/declaration/declaration.type'
import { ITBankQuestionnaire } from '@/entityes/invoice/TBankQuestionnaire/t_bank_questionnaire.type'

interface IProps {
  declaration: IDeclaration
  questionnaire: ITBankQuestionnaire
}

export default /*@__PURE__*/_defineComponent({
  __name: 'tinkoffForm',
  props: {
    declaration: {},
    questionnaire: {}
  },
  setup(__props: any) {

const props = __props
const version = computed(() => props.declaration.correction_number)
const resident = computed(() => props.questionnaire?.is_resident)
const foreignIncomes = computed(
  () => !!props?.questionnaire && props?.questionnaire?.income_sources?.['foreign-investment-incomes']?.chosenOptions
)
const hasTinkoffDividends = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('tinkoff-foreign-securities-dividends')
  } else {
    return false
  }
})
const hasForeignBrokerAccounts = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('foreign-broker-account')
  } else {
    return false
  }
})
const hasRussianBrokerAccounts = computed(() => {
  if (foreignIncomes.value) {
    return Object.keys(foreignIncomes.value).includes('russian-brokers-foreign-securities-dividends')
  } else {
    return false
  }
})

const res = computed(() => {
  return {
    dividends: {
      title: 'Дивиденды Тинькофф',
      show: hasTinkoffDividends.value,
    },
    foreignBrokers: {
      title: 'Зарубежные брокеры',
      show: hasForeignBrokerAccounts.value,
    },
    russianBrokers: {
      title: 'Брокеры РФ',
      show: hasRussianBrokerAccounts.value,
    },
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, "Версия декларации: " + _toDisplayString(_ctx.declaration.correction_number), 1),
    _createElementVNode("p", _hoisted_2, "Резидент: " + _toDisplayString(resident.value ? 'да' : 'нет'), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(res.value, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.title,
        class: "mb-2"
      }, [
        (item.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(item.title), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})