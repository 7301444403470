import socialIcon from '@/assets/pre_add_incomes_icons/social_icon.svg'
export function SocialRefundsDescriber() {
  const tagsList = [
    {
      tag: 'med',
      label: 'Лечение',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
    {
      tag: 'exp_med',
      label: 'Дорогостоящее лечение',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 4,
    },
    {
      tag: 'drugs',
      label: 'Лекарственные препараты',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
    {
      tag: 'fitness',
      label: 'Физкультура и оздоровление',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
    // {
    //   tag: 'self_learning_foreign',
    //   label: 'Свое обучение вне РФ',
    //   meta: {
    //     show_pre: true,
    //   },
    //   limitGroup: 1,
    // },
    {
      tag: 'self_learning',
      label: 'Свое обучение',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
    {
      tag: 'children_learnings',
      label: 'Обучение детей',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 2,
    },
    {
      tag: 'qualification_assessment',
      label: 'Оценка квалификации',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
    {
      tag: 'charity',
      label: 'Благотворительность (25% или 30%)',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 3,
    },
    {
      tag: 'live_insure',
      label: 'Добровольное страхование',
      icon: socialIcon,
      meta: {
        show_pre: false,
      },
      limitGroup: 1,
    },
    {
      tag: 'voluntary_insurance',
      label: 'Добровольное медицинское страхование',
      icon: socialIcon,
      meta: {
        show_pre: true,
      },
      limitGroup: 1,
    },
  ]
  return {
    tagsList,
  }
}
