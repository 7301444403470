import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-4" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = {
  key: 1,
  class: "font-weight--extra"
}
const _hoisted_4 = { class: "flex items-center justify-between" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "font-weight--semi" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "flex items-center justify-between" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "flex items-center justify-between" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "flex items-center justify-between" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "flex items-center justify-between" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "flex items-center justify-between" }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "font-weight--semi" }
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = { class: "flex items-center mb-2" }
const _hoisted_25 = { class: "font-weight--extra" }
const _hoisted_26 = { class: "flex items-center mb-2" }
const _hoisted_27 = { class: "font-weight--extra" }
const _hoisted_28 = { class: "flex items-center mb-2" }
const _hoisted_29 = { class: "font-weight--extra" }
const _hoisted_30 = { class: "mb-2" }
const _hoisted_31 = { class: "font-weight--extra" }
const _hoisted_32 = { class: "flex items-center mb-2" }
const _hoisted_33 = { class: "font-weight--extra" }
const _hoisted_34 = { class: "flex items-center mb-2" }
const _hoisted_35 = { class: "font-weight--extra" }
const _hoisted_36 = { class: "flex items-center mb-2" }
const _hoisted_37 = { class: "font-weight--extra margin-h--left-s" }
const _hoisted_38 = { class: "flex items-center mb-2" }
const _hoisted_39 = { class: "font-weight--extra margin-h--left-s" }

import { reactive, ref } from 'vue'
import CalculatedIncomeTableFilter from '@/components/Admin/CalculatedIncomeTableFilter.vue'
import CalculatedExpenses from '@/04-Widgets/TradeOperations/ui/CalculatedExpenses.vue'
import {
  ICalcIncome,
  ICalcIncomeList,
  IIncomeCodes,
  IIncomeOkvs,
} from '@/entityes/invoice/trade_operations/trade_operations.type'
import {
  CirclePlusFilled,
  Operation,
  EditPen,
  Remove,
  CircleCheckFilled,
  InfoFilled,
  Document,
} from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import useTradeOperationsRepository from '@/entityes/invoice/trade_operations/trade_operation.repository'

interface IProps {
  id: string | number
  declarationId: string | number
  calculatedIncomes: ICalcIncomeList
  incomeCodes: IIncomeCodes
  okvs: IIncomeOkvs
  tPages: number
  cPage: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CalculatedIncomes',
  props: {
    id: {},
    declarationId: {},
    calculatedIncomes: {},
    incomeCodes: {},
    okvs: {},
    tPages: {},
    cPage: {}
  },
  setup(__props: any) {

const props = __props
const incomesToDelete = reactive<ICalcIncome[]>([])
const currentEditingRow = ref<any>(undefined)
const calculatedExpenses = ref<InstanceType<typeof CalculatedExpenses> | null>(null)
const filter = ref('')
const sort = ref('')
const search = ref('')
const loading = ref(false)
const { calculatedIncomesInit, updateCalcIncome, destroyCalcIncome, createIncome, deleteSelectedRows } =
  useTradeOperationsRepository()

const chooseToBulkDelete = (val: ICalcIncome[]) => {
  incomesToDelete.splice(0, incomesToDelete.length, ...val.map((i) => i.id))
}
const filterEvent = (val: string) => {
  loading.value = true
  filter.value = val
  calculatedIncomesInit(props.id, 1, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса фильтрации ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function openIncomeEditor(val: Record<string, string>) {
  if (currentEditingRow.value) currentEditingRow.value.showEditor = false
  val.showEditor = true
  currentEditingRow.value = val
}
function closeIncomeEditor() {
  if (currentEditingRow.value === undefined) return
  currentEditingRow.value.showEditor = false
  currentEditingRow.value = undefined
}
function sortEvent(val: string) {
  loading.value = true
  sort.value = val
  calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function deleteSelectedIncomes() {
  loading.value = true
  deleteSelectedRows(incomesToDelete)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'удалены сделки',
        type: 'success',
      })
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
        .catch((err) => {
          ElNotification({
            title: 'Ошибка',
            message: `получения списка доходов ${err}`,
            type: 'error',
          })
        })
        .finally(() => (loading.value = false))
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `удаления сделок ${err}`,
        type: 'error',
      })
    })
  // emit('delete-incomes', incomesToDelete)
}
function showExpensesDialog(id: string | number, ticker: string) {
  calculatedExpenses.value.openDialog(id, ticker)
}
function create() {
  loading.value = true
  createIncome(props.declarationId)
    .then(() => {
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
        .finally(() => (loading.value = false))
        .then(() => {
          ElNotification({
            title: 'Успешно',
            message: `создан новый доход`,
            type: 'success',
          })
        })
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `создания дохода | ${err}`,
        type: 'error',
      })
    })
}
function update(income: ICalcIncome) {
  loading.value = true
  updateCalcIncome(income).then(() => {
    ElNotification({
      title: 'Успешно',
      message: `обновлена сделка ${income.ticker_description}`,
      type: 'success',
    })
    calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `запроса фильтрации ${err}`,
          type: 'error',
        })
      })
      .finally(() => (loading.value = false))
  })
}
function destroy(row: ICalcIncome) {
  loading.value = true
  destroyCalcIncome(row.id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `удален доход ${row.id}`,
        type: 'success',
      })
      calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value).finally(
        () => (loading.value = false)
      )
    })
    .catch((err) => {
      loading.value = false
      ElNotification({
        title: 'Ошибка',
        message: `удаления дохода ${row.id} | ${err}`,
        type: 'error',
      })
    })
}
function changePage(page: number | string) {
  loading.value = true
  calculatedIncomesInit(props.id, page, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `получения списка доходов ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}
function tickerFilter(ticker: string) {
  loading.value = true
  search.value = ticker
  calculatedIncomesInit(props.id, props.cPage, search.value, filter.value, sort.value)
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса поиска ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_pagination, {
        "current-page": props.cPage,
        total: props.tPages,
        layout: "prev, pager, next",
        "page-size": 1,
        onCurrentChange: changePage,
        background: "",
        size: "small"
      }, null, 8, ["current-page", "total"]),
      _createVNode(_component_el_input, {
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        placeholder: "Поиск по доходам",
        style: {"width":"300px"},
        onInput: tickerFilter
      }, null, 8, ["modelValue"])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: props.calculatedIncomes,
        class: _normalizeClass(["mb-4", loading.value ? 'table-height-loading' : '']),
        "table-layout": "auto",
        stripe: "",
        border: "",
        onKeyup: [
          _withKeys(closeIncomeEditor, ["esc"]),
          _cache[1] || (_cache[1] = _withKeys(($event: any) => (update(currentEditingRow.value)), ["enter"]))
        ],
        tabindex: "-1",
        onSelectionChange: chooseToBulkDelete,
        onCellDblclick: openIncomeEditor
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            type: "selection",
            width: "40"
          }),
          _createVNode(_component_el_table_column, {
            fixed: "",
            label: "ticker",
            width: "140"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _cache[2] || (_cache[2] = _createElementVNode("p", null, "ticker", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "ticker_description",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.ticker_description,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.ticker_description) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(scope.row.ticker_description), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { width: "140" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[3] || (_cache[3] = _createElementVNode("p", null, "currency sum", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "currency_sum",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.currency_sum,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.currency_sum) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(scope.row.currency_sum), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { width: "180" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createElementVNode("p", null, "date", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "date",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_date_picker, {
                    key: 0,
                    modelValue: scope.row.date,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.date) = $event),
                    type: "datetime",
                    format: "YYYY/MM/DD hh:mm:ss",
                    "value-format": "YYYY-MM-DD",
                    size: "small",
                    style: {"width":"160px !important"}
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(scope.row?.date?.split('T')[0]), 1),
                      _createTextVNode(" " + _toDisplayString(scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0]), 1)
                    ])
                  ]))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "income code",
            width: "120"
          }, {
            header: _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("div", null, "income code", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "income_code",
                "setup-value": _ctx.incomeCodes,
                onSearchEvent: filterEvent,
                element: "filter"
              }, null, 8, ["setup-value"])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.income_code,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.income_code) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(scope.row.income_code), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "oksm_country",
            width: "126"
          }, {
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.oksm_country,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.oksm_country) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(scope.row.oksm_country), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, { width: "120" }, {
            header: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("p", null, "okv country", -1)),
              _createVNode(CalculatedIncomeTableFilter, {
                "search-label": "okv_country",
                "setup-value": _ctx.okvs,
                onSearchEvent: filterEvent,
                element: "filter"
              }, null, 8, ["setup-value"])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.okv_country,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.okv_country) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(scope.row.okv_country), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "price",
            width: "140"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _cache[7] || (_cache[7] = _createElementVNode("p", null, "price", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "price",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.price,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.price) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(scope.row.price), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "qty",
            width: "100"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_14, [
                _cache[8] || (_cache[8] = _createElementVNode("p", null, "qty", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "qty",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.qty,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.qty) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(scope.row.qty), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "rub_sum",
            width: "140"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                _cache[9] || (_cache[9] = _createElementVNode("p", null, "rub_sum", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "rub_sum",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.rub_sum,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.rub_sum) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(scope.row.rub_sum), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "tax_curr_paid",
            width: "140"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _cache[10] || (_cache[10] = _createElementVNode("p", null, "tax_curr_paid", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "tax_curr_paid",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_input, {
                    key: 0,
                    size: "small",
                    modelValue: scope.row.tax_currency_paid,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.tax_currency_paid) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(scope.row.tax_currency_paid), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "tax_date",
            width: "180"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_20, [
                _cache[11] || (_cache[11] = _createElementVNode("p", null, "tax_date", -1)),
                _createVNode(CalculatedIncomeTableFilter, {
                  "search-label": "tax_date",
                  onSearchEvent: sortEvent,
                  element: "sort"
                })
              ])
            ]),
            default: _withCtx((scope) => [
              (scope.row.showEditor)
                ? (_openBlock(), _createBlock(_component_el_date_picker, {
                    key: 0,
                    modelValue: scope.row.tax_date,
                    "onUpdate:modelValue": ($event: any) => ((scope.row.tax_date) = $event),
                    format: "YYYY/MM/DD hh:mm:ss",
                    "value-format": "YYYY-MM-DD",
                    size: "small",
                    style: {"width":"160px !important"}
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, _toDisplayString(scope.row?.date?.split('T')[0]), 1),
                    _createTextVNode(" " + _toDisplayString(scope.row?.date?.split('T')[1]?.split('+')[0]?.split('.')[0]), 1)
                  ]))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            fixed: "right",
            width: "40"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_23, [
                (incomesToDelete.length > 0)
                  ? (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: 0,
                      class: "box-item",
                      effect: "dark",
                      content: "Удалить все",
                      placement: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          onClick: deleteSelectedIncomes,
                          type: "danger",
                          size: "small",
                          icon: _unref(Remove)
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_el_tooltip, {
                      key: 1,
                      class: "box-item",
                      effect: "dark",
                      content: "Создать доход",
                      placement: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          icon: _unref(CirclePlusFilled),
                          type: "primary",
                          onClick: create
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }))
              ])
            ]),
            default: _withCtx((scope) => [
              _createVNode(_component_el_dropdown, { trigger: "click" }, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, {
                        onClick: ($event: any) => (showExpensesDialog(scope.row.id, scope.row.ticker_description)),
                        disabled: scope.row.showEditor,
                        class: "p-0 py-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_tooltip, {
                            disabled: "",
                            class: "box-item",
                            effect: "dark",
                            content: "Расходы",
                            placement: "top"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                type: "primary",
                                size: "small",
                                text: "",
                                disabled: scope.row.showEditor,
                                icon: _unref(Document),
                                class: "w-full"
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode(" Расходы ")
                                ])),
                                _: 2
                              }, 1032, ["disabled", "icon"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"]),
                      _createVNode(_component_el_dropdown_item, { class: "p-0 py-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_popover, {
                            placement: "left",
                            title: "Инфо о доходе",
                            width: 300,
                            trigger: "hover"
                          }, {
                            reference: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: _unref(InfoFilled),
                                disabled: scope.row.showEditor,
                                text: "",
                                size: "small",
                                type: "primary",
                                class: "w-full"
                              }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" Доп информация ")
                                ])),
                                _: 2
                              }, 1032, ["icon", "disabled"])
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_24, [
                                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "id:", -1)),
                                _createElementVNode("p", _hoisted_25, _toDisplayString(scope.row.id), 1)
                              ]),
                              _createElementVNode("div", _hoisted_26, [
                                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "broker:", -1)),
                                _createElementVNode("p", _hoisted_27, _toDisplayString(scope.row.broker), 1)
                              ]),
                              _createElementVNode("div", _hoisted_28, [
                                _cache[16] || (_cache[16] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "currency_repricing:", -1)),
                                _createElementVNode("p", _hoisted_29, _toDisplayString(scope.row.currency_repricing), 1)
                              ]),
                              _createElementVNode("div", _hoisted_30, [
                                _cache[17] || (_cache[17] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "source_file_name:", -1)),
                                _createElementVNode("p", _hoisted_31, _toDisplayString(scope.row.source_file_name), 1)
                              ]),
                              _createElementVNode("div", _hoisted_32, [
                                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "tax_rate:", -1)),
                                _createElementVNode("p", _hoisted_33, _toDisplayString(scope.row.tax_rate), 1)
                              ]),
                              _createElementVNode("div", _hoisted_34, [
                                _cache[19] || (_cache[19] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "tax_rub_paid:", -1)),
                                _createElementVNode("p", _hoisted_35, _toDisplayString(scope.row.tax_rub_paid), 1)
                              ]),
                              _createElementVNode("div", _hoisted_36, [
                                _cache[20] || (_cache[20] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "tax_to_pay %:", -1)),
                                _createElementVNode("p", _hoisted_37, _toDisplayString(scope.row.tax_to_pay_percent), 1)
                              ]),
                              _createElementVNode("div", _hoisted_38, [
                                _cache[21] || (_cache[21] = _createElementVNode("p", { class: "mr-2 font-bold font-color--purple" }, "tax_withhold %:", -1)),
                                _createElementVNode("p", _hoisted_39, _toDisplayString(scope.row.tax_withhold_percent), 1)
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_dropdown_item, { class: "p-0 py-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            icon: _unref(EditPen),
                            text: "",
                            size: "small",
                            type: "primary",
                            disabled: scope.row.showEditor,
                            class: "w-full",
                            onClick: ($event: any) => (openIncomeEditor(scope.row))
                          }, {
                            default: _withCtx(() => _cache[22] || (_cache[22] = [
                              _createTextVNode(" Редактировать ")
                            ])),
                            _: 2
                          }, 1032, ["icon", "disabled", "onClick"])
                        ]),
                        _: 2
                      }, 1024),
                      (scope.row.showEditor)
                        ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: 0,
                            class: "p-0 py-2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                text: "",
                                icon: _unref(CircleCheckFilled),
                                class: "w-full",
                                onClick: ($event: any) => (update(scope.row)),
                                size: "small",
                                type: "success"
                              }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode(" Сохранить ")
                                ])),
                                _: 2
                              }, 1032, ["icon", "onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (scope.row.showEditor)
                        ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: 1,
                            onClick: closeIncomeEditor,
                            class: "p-0 py-2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                class: "w-full",
                                type: "danger",
                                text: "",
                                icon: _unref(Remove),
                                size: "small"
                              }, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode(" Отменить ")
                                ])),
                                _: 1
                              }, 8, ["icon"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_el_dropdown_item, {
                        class: "px-0 py-2",
                        onClick: ($event: any) => (destroy(scope.row)),
                        disabled: scope.row.showEditor
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            disabled: scope.row.showEditor,
                            text: "",
                            icon: _unref(Remove),
                            type: "danger",
                            size: "small",
                            class: "w-full"
                          }, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode(" Удалить ")
                            ])),
                            _: 2
                          }, 1032, ["disabled", "icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "info",
                    icon: _unref(Operation),
                    size: "small"
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "class"])), [
        [_directive_loading, loading.value]
      ]),
      _createVNode(_component_el_pagination, {
        "hide-on-single-page": props.tPages === 1,
        "current-page": props.cPage,
        total: props.tPages,
        layout: "prev, pager, next",
        "page-size": 1,
        onCurrentChange: changePage,
        background: "",
        size: "small"
      }, null, 8, ["hide-on-single-page", "current-page", "total"]),
      _createVNode(CalculatedExpenses, {
        ref_key: "calculatedExpenses",
        ref: calculatedExpenses
      }, null, 512)
    ])), [
      [_directive_loading, loading.value]
    ])
  ]))
}
}

})