import {
  AdditionalItem,
  AdditionalItemValue,
  Deduction,
  useCollectionWithSituation,
} from '@/06-Entities/IncomeAndRefund'
import { DeductionDescriber } from './DeductionDescriber'
import { useRequestService } from '@/use/useRequestService'
import HeaderPreview from '../ui/HeaderPreview.vue'
import { ValidationAddError } from '@/components/Invoice/Declaration/support/Errors'
import { SocialRefundsDescriber } from './SocialRefundsDescriber'
import { reactive, ref, UnwrapNestedRefs } from 'vue'
import { ElMessageBox } from 'element-plus'

interface IDeductionToServer extends AdditionalItemValue {}

export function useDeductionCollection() {
  const describers = DeductionDescriber
  const { fetch } = useRequestService()
  const collectionObj = useCollectionWithSituation<Deduction>({
    describers,
    situationFieldName: 'type',
  })
  const meta = {
    headerComponent: HeaderPreview,
  }

  function createNew(type: string) {
    if (type === 'material_losses') {
      if (collectionObj.showItemDescibersWithHaveElements.value.some((item) => item.tag === 'material_losses')) {
        return
      }
    }
    collectionObj.createNew(type)
  }

  function buildDataForServer() {
    return new Promise<IDeductionToServer[]>((resolve, reject) => {
      const result = collectionObj.collection.map((item) => {
        if (item.valueFromServer && !item.fieldWasEdited)
          return {
            ...item.toJson(),
            valid: true,
          }
        return item.toJson()
      })
      if (result.some((item) => !item.valid) || !valid.value) {
        reject(new ValidationAddError())
      } else resolve(result as IDeductionToServer[])
    })
  }

  function getFromServer(declarationId: number | string) {
    return new Promise<Deduction[]>((resolve, reject) => {
      fetch<{ deductions: Deduction[] }>(`/declarations/${declarationId}/deductions`)
        .then((res) => {
          collectionObj.mergeValueFromServer(res.deductions)
          resolve(res.deductions)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  function sentToServer(declarationId: number | string) {
    return new Promise<Deduction[]>((resolve, reject) => {
      buildDataForServer()
        .then((res) => {
          fetch<{ deductions: Deduction[] }>(`/declarations/${declarationId}/deductions`, {
            method: 'patch',
            body: {
              deductions: res,
            },
          })
            .then((res) => resolve(res.deductions))
            .catch((err) => reject(err))
        })
        .catch((err) => reject(err))
    })
  }
  const valid = ref(true)
  const openDialog = ref(false)
  const readDialog = reactive<Record<string, boolean>>({})
  function checkLimit(yearToCalc: number) {
    if (openDialog.value) return
    const socialRefund = collectionObj.collection.filter((item) => {
      return item.situationName === 'social_refund'
    })
    if (!checkSocialLimit(socialRefund, yearToCalc)) {
      return (valid.value = false)
    }

    const propertyTaxDeduction = collectionObj.collection.filter(
      (item) => item.situationName === 'property_tax_deduction'
    )
    if (propertyTaxDeduction.reduce((acc, item) => (acc += Number(item.item.sum)), <number>0) > 2000000) {
      openDialog.value = true
      if (readDialog.propertyTaxDeduction) return
      ElMessageBox({
        title: 'Вычет покупки жилья',
        message: `<p>Сумма вычета за покупку жилья не должна превышать 2000000 рублей.</p><p>Внесите изменения по данным вычетам.</p>`,
      }).finally(() => {
        openDialog.value = false
        readDialog.propertyTaxDeduction = true
      })
      return (valid.value = false)
    }
    const propertyDeductionMortgage = collectionObj.collection.filter(
      (item) => item.situationName === 'property_deduction_mortgage'
    )
    if (propertyDeductionMortgage.reduce((acc, item) => (acc += Number(item.item.sum)), <number>0) > 3000000) {
      openDialog.value = true
      if (readDialog.propertyDeductionMortgage) return
      ElMessageBox({
        title: 'Вычет покупки жилья',
        message: `<p>Сумма вычета по процентам по ипотеке не должна превышать 3000000 рублей.</p><p>Внесите изменения по данным вычетам.</p>`,
      }).finally(() => {
        openDialog.value = false
        readDialog.propertyDeductionMortgage = true
      })
      return (valid.value = false)
    }
    return (valid.value = true)
  }
  function checkSocialLimit(items: UnwrapNestedRefs<AdditionalItem<Deduction>>[], yearToCalc: number) {
    const tags = SocialRefundsDescriber().tagsList
    const group1tag = tags.filter((item) => item.limitGroup === 1).map((item) => item.tag)
    const group1limit = yearToCalc >= 2024 ? 150000 : 120000
    const group2tag = tags.filter((item) => item.limitGroup === 2).map((item) => item.tag)
    if (
      items
        .filter((item) => group1tag.includes(item.item.tag))
        .reduce((acc, item) => {
          return (acc += Number(item.item.sum))
        }, <number>0) > group1limit
    ) {
      openDialog.value = true
      if (readDialog.social1) return
      ElMessageBox({
        title: 'Социальные вычеты',
        message: `<p>Сумма вычетов по леченю, дорогим лекарствам, свое обучение, оценка квалицикации, добровольное страхование, добровольное медицинское страхование не должна превышать ${group1limit} рублей.</p><p>Внесите изменения по данным вычетам.</p>`,
        dangerouslyUseHTMLString: true,
      }).finally(() => {
        openDialog.value = false
        readDialog.social1 = true
      })
      return false
    }
    const group2 = items.filter((item) => group2tag.includes(item.item.tag))
    if (yearToCalc < 2024 && group2.some((item) => Number(item.item.sum) > 50000)) {
      openDialog.value = true
      if (readDialog.social2) return
      ElMessageBox({
        title: 'Социальные вычета',
        message:
          '<p>Сумма вычета на обучение одного ребенка не должна превышать 50000 рублей.</p><p>Внесите изменения по данным вычетам.</p>',
        dangerouslyUseHTMLString: true,
      }).finally(() => {
        openDialog.value = false
        readDialog.social2 = true
      })
      return false
    }
    if (yearToCalc >= 2024 && group2.reduce((acc, item) => (acc += Number(item.item.sum)), <number>0) > 110000) {
      openDialog.value = true
      ElMessageBox({
        title: 'Социальные вычета',
        message:
          '<p>Сумма вычета на обучение детей не должна превышать 110000 рублей.</p><p>Внесите изменения по данным вычетам.</p>',
        dangerouslyUseHTMLString: true,
      }).finally(() => {
        openDialog.value = false
        readDialog.social2 = true
      })
      return false
    }
    return true
  }

  return {
    ...collectionObj,
    meta,
    createNew,
    getFromServer,
    sentToServer,
    checkLimit,
  }
}
