<script lang="ts" setup>
import { number, object, string } from 'yup'
import { ContractIds, LiveInsureContractType } from '../model/LiveInsureContractType'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { UnwrapNestedRefs, watch } from 'vue'
import { AdditionalItem, AdditionalItemValue, Deduction } from '@/06-Entities/IncomeAndRefund'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import useWindowWidth from '@/use/useWindowWidth'
import { SocialRefundsDescriber } from '../model/SocialRefundsDescriber'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import { cloneDeep } from 'lodash'
import { setTouchedHelper } from '@/04-Widgets/AdditionelIncome/lib/setTouchedHelper'
import { useDialog } from 'element-plus'

interface IProps {
  yearToCalc: number
  item: UnwrapNestedRefs<AdditionalItem<Deduction>>
  helpDescriber: any
}
const props = defineProps<IProps>()
interface IEmits {
  (e: 'update-item', value: AdditionalItemValue): void
}
const emit = defineEmits<IEmits>()

const schema = object({
  tag: string().required(),
  sum: number().required('Это поле обязательно для заполнения'),
  currency: string().length(3).required().default('RUB'),
  other_attributes: object({
    name: string().test('name-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    inn: number().test('inn-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return number().required().isValidSync(value)
      }
      return true
    }),
    kpp: number().test('kpp-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return number().required().isValidSync(value)
      }
      return true
    }),
    contract_id: string().test('contract-test-required', 'Поле обязательное к заполнению', (value, context) => {
      const tag = context.from?.[1].value.tag
      const contract_type = context.from?.[0].value.contract_type
      if (tag === 'live_insure' && contract_type !== '4') {
        return string().required().isValidSync(value)
      }
      return true
    }),
    date: string()
      .checkYearValue(props.yearToCalc)
      .test('date-test-required', 'Поле обязательное к заполнению', (value, context) => {
        const tag = context.from?.[1].value.tag
        const contract_type = context.from?.[0].value.contract_type
        if (tag === 'live_insure' && contract_type !== '4') {
          return string().required().isValidSync(value)
        }
        return true
      }),
    contract_type: string()
      .oneOf<ContractIds>(LiveInsureContractType.map((item) => item.id))
      .test('contract_type-required', 'Поле обязательное к заполнению', (value, context) => {
        const tag = context.from?.[1].value.tag
        if (tag === 'live_insure') {
          return string().required().isValidSync(value)
        }
        return true
      }),
    foreign: number(),
  }),
})
const validationSchema = toTypedSchema(schema)

const { values, validate, errorBag } = useForm({
  validateOnMount: true,
  validationSchema,
  initialValues: {
    ...Object.assign({}, { ...props.item.item }),
  },
})
const { screenLessValue } = useWindowWidth()
const tags = SocialRefundsDescriber().tagsList

setTouchedHelper(props.item)
async function prepareItem() {
  console.log(await validate())
  const valid = (await validate()).valid
  const result = Object.assign(
    {
      ...cloneDeep(valid === true ? schema.cast(values) : values),
    },
    {
      valid,
    }
  )
  emit('update-item', result)
}
watch(
  values,
  () => {
    prepareItem()
  },
  {
    immediate: true,
  }
)
</script>

<template>
  <div class="p-4">
    <p class="font-semibold mb-1">Тип вычета</p>
    <el-row class="mb-4">
      <el-col :span="12">
        <form-item name="tag" v-slot="{ field, value }">
          <el-select :model-value="value" v-bind="field" filterable placeholder="Выберите тип вычета">
            <el-option v-for="key in tags" :key="key.tag" :value="key.tag" :label="key.label">
              <div class="flex items-center justify-between">
                <span>
                  {{ key.label }}
                </span>
                <question-popover>
                  <div v-html="helpDescriber[key?.tag]" />
                </question-popover>
              </div>
            </el-option>
          </el-select>
        </form-item>
      </el-col>
    </el-row>
    <div v-if="values.tag">
      <el-row>
        <el-col :span="12" v-if="values.tag === 'live_insure'">
          <div>
            <p class="font-semibold mb-1">Вид договора</p>
            <form-item name="other_attributes.contract_type" v-slot="{ field, value }">
              <el-select
                :model-value="value"
                v-bind="field"
                class="mb-4 w-full"
                filterable
                placeholder="Выберите тип контракта"
              >
                <el-option
                  v-for="item in LiveInsureContractType"
                  :key="item.title"
                  :value="item.id"
                  :label="item.title"
                >
                  <div class="flex items-center justify-between">
                    <span>{{ item.title }}</span>
                    <question-popover>
                      <div v-html="helpDescriber[item?.tag]" />
                    </question-popover>
                  </div>
                </el-option>
              </el-select>
            </form-item>
          </div>
        </el-col>
      </el-row>
      <div v-if="values.tag === 'live_insure' && values.other_attributes?.contract_type !== '4'">
        <div class="mb-4 flex flex-wrap items-center">
          <div class="mr-4">
            <p class="font-semibold mb-1">Наименование организации или фонда</p>
            <form-item name="other_attributes.name" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div class="mr-2">
            <p class="font-semibold mb-1">ИНН</p>
            <form-item name="other_attributes.inn" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div>
            <p class="mb-1 font-semibold">КПП</p>
            <form-item name="other_attributes.kpp" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
        </div>
        <div class="flex flex-wrap items-center">
          <div class="mr-4">
            <p class="mb-1 font-semibold">Номер договора</p>
            <form-item name="other_attributes.contract_id" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div class="mr-4">
            <p class="font-semibold mb-1">Сумма взноса</p>
            <form-item name="sum" v-slot="{ field, value }">
              <el-input :model-value="value" v-bind="field" />
            </form-item>
          </div>
          <div>
            <p class="font-semibold mb-1">Дата заключения договора</p>
            <form-item name="other_attributes.date" v-slot="{ field, value }">
              <el-date-picker
                :model-value="value"
                v-bind="field"
                type="date"
                placeholder="Выберите дату"
                format="DD.MM.YYYY"
                value-format="DD.MM.YYYY"
              />
            </form-item>
          </div>
        </div>
      </div>
      <div v-else>
        <el-row>
          <el-col :span="10">
            <div class="w-full mb-4">
              <p class="font-semibold mb-1">Сумма</p>
              <form-item name="sum" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" />
              </form-item>
            </div>
            <div class="w-full" v-if="values.tag === 'children_learnings'">
              <p class="font-semibold mb-1">Сумма вне РФ</p>
              <form-item name="other_attributes.foreign" v-slot="{ field, value }">
                <el-input :model-value="value" v-bind="field" />
              </form-item>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<style lang="sass"></style>
