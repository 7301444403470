import { MaskOptions } from 'maska'

export const moneyMask: MaskOptions = {
  mask: '0.99',
  tokens: {
    9: { pattern: /[0-9]/, optional: true },
    0: { pattern: /[0-9]/, multiple: true },
  },
}

export const integerMask: MaskOptions = {
  mask: '0',
  tokens: {
    0: { pattern: /[0-9]/, multiple: true },
  },
}

export const passportIssuedCode: MaskOptions = {
  mask: '###-###',
  tokens: {
    '#': { pattern: /[0-9]/, optional: true },
  },
}

export const passportNumber: MaskOptions = {
  mask: '#### ######',
  tokens: {
    '#': { pattern: /[0-9]/, optional: true },
  },
}

export const taxInspectionCode: MaskOptions = {
  mask: '####',
  tokens: {
    '#': { pattern: /[0-9]/, optional: true },
  },
}

export const inn: MaskOptions = {
  mask: '############',
  tokens: {
    '#': { pattern: /[0-9]/, optional: true },
  },
}

export const oktmo: MaskOptions = {
  mask: '############',
  tokens: {
    '#': { pattern: /[0-9]/, optional: true },
  },
}

export const onlyLatin: MaskOptions = {
  mask: '####################################################################################################################################',
  tokens: {
    '#': { pattern: /^[A-Za-z0-9\s,\/.\-]+$/ },
  },
}
